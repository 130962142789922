import React, { useEffect, useContext, useState, useRef } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";

import "./member-inbox-report.scss";
import { isWeiXin, urlTranslate } from "../../utils/wx_tools";
import { rem, isMobile } from "../../utils/tools";
import AuthStore from "../../stores/authStore";
import MemberInboxStore from "../../stores/memberInboxStore";
import PayErrorDialog from "./PayErrorDialog";
import PaySuccessDialog from "./PaySuccessDialog";
import CostDialog from "./CostDialog";
import LoginDialog from "./LoginDialog";
import NewBuyDialog from "./NewBuyDialog";
import { Loading, IconHeading, Icon } from "../shared";
import lockIcon from "../../styles/images/inbox__lock-icon.png";
import { unlockMemberApi } from "../../api/index";
import { message } from "antd";
import buyType from "./buyType";
import backImg from "../../styles/images/member-inbox-back.png";

const MemberInboxReport: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const thumbnailsRef = useRef(null);
  const {
    memberInfo,
    fetchMemberInfo,
    memberLoading,
    currentUser,
    isNewMember,
    windowExpire,
  } = useContext(AuthStore);
  const {
    fetchDetail,
    detail,
    detailLoading,
    setDialog,
    updateActiveThumbnail,
    setDiscount,
    isBack,
    setIsBack,
  } = useContext(MemberInboxStore);

  const [arrowLeftColor, setArrowLeftColor] = useState("#C6CBD4");
  const [arrowRightColor, setArrowRightColor] = useState("#C6CBD4");
  const [buy, setBuy] = useState(false);
  const [disable, setDisable] = useState(false);

  const authFn = () => {
    if (!memberLoading && !memberInfo) {
      window.sessionStorage.setItem("id", id);
      setDialog("login", true);
    }
    // 已登录未实名认证
    currentUser && !currentUser.mobile_verified && history.replace("/auth");
  };

  const onScrollEvent = () => {
    if (!detailLoading) {
      const dom = document.getElementsByClassName(
        "member-inbox-report__detail-content"
      )[0];
      if (dom && dom.scrollTop > dom.clientHeight * 0.5) {
        setIsBack(true);
      } else {
        setIsBack(false);
      }
    }
  };

  useEffect(() => {
    if (isWeiXin()) {
      fetchMemberInfo();
      if (!(currentUser && !currentUser.mobile_verified)) {
        fetchDetail(id);
        window.sessionStorage.removeItem("login");
        setIsBack(false);
      }
    } else {
      if (isMobile()) {
        history.replace("/inbox-pc");
      } else {
        urlTranslate(history);
      }
    }
  }, []);

  useEffect(() => {
    if (!memberLoading) {
      authFn();
    }
  }, [memberLoading]);

  useEffect(() => {
    if (memberInfo && detail) {
      if (memberInfo.points >= detail.unlockSpend) {
        setBuy(true);
      }
    }
  }, [memberInfo, detail]);

  const handleLeftClick = () => {
    if (!(detail.reportCatalog.activeIndex === 0)) {
      updateActiveThumbnail(detail.reportCatalog.activeIndex - 1);
      if (
        detail.reportCatalog.activeIndex <
        detail.reportCatalog.images.length - 2
      ) {
        thumbnailsRef.current.scrollTo({ right: 330 });
      }
    }
  };

  const handleRightClick = () => {
    if (
      !(
        detail.reportCatalog.activeIndex ===
        detail.reportCatalog.images.length - 1
      )
    ) {
      updateActiveThumbnail(detail.reportCatalog.activeIndex + 1);
      if (detail.reportCatalog.activeIndex > 2) {
        thumbnailsRef.current.scrollTo({ left: 330 });
      }
    }
  };

  const lockFn = async () => {
    if (disable) {
      return false;
    }
    setDisable(true);
    await unlockMemberApi({
      id,
    });
    await fetchMemberInfo();
    fetchDetail(id);
    message.open({
      type: "success",
      duration: 3,
      className: "ant-toast",
      content: "已解锁",
    });
    setDisable(false);
  };

  return (
    <div className="member-inbox-report__detail">
      <Helmet>
        <title>{detailLoading ? "" : detail.title} ｜ 机器之心PRO</title>
      </Helmet>
      <NewBuyDialog onSuccess={() => fetchDetail(id)} />
      <CostDialog onSuccess={() => fetchDetail(id)} />
      <PayErrorDialog />
      <PaySuccessDialog />
      <LoginDialog />
      {detailLoading ? (
        <Loading />
      ) : (
        <>
          {isBack && (
            <div
              className="member-inbox-detail-back"
              onClick={() => {
                window.localStorage.removeItem("gangLogin");
                history.replace("/m/inbox");
              }}
            >
              <img src={backImg} alt="back" />
            </div>
          )}
          <div
            className="member-inbox-report__detail-content"
            onScrollCapture={onScrollEvent}
            style={
              (memberInfo && memberInfo.listVip) || detail.unlocked
                ? {
                    paddingBottom: rem(30 + 46),
                  }
                : {
                    paddingBottom: rem(30),
                  }
            }
          >
            {detail.fullCoverImageUrl && (
              <div className="content__front-cover">
                <img src={detail.fullCoverImageUrl} alt="front-cover" />
              </div>
            )}
            {detail.title && <p className="content__title">{detail.title}</p>}
            {(detail.author || detail.date) && (
              <div className="content-info">
                {detail.author && <span>{detail.author}</span>}
                {detail.author && detail.date && <span>|</span>}
                {detail.date && <span>{detail.date}</span>}
              </div>
            )}
            {(detail.pageSize ||
              (detail.hotTags && detail.hotTags.length > 0)) && (
              <div className="content-line" />
            )}
            {detail.hotTags && detail.hotTags.length > 0 && (
              <div className="content-group">
                <span>标签：</span>
                <span>{detail.hotTags.join("、")}</span>
              </div>
            )}
            {detail.pageSize && (
              <div className="content-group">
                <span>报告页数：</span>
                <span>{detail.pageSize}</span>
              </div>
            )}

            {detail.summary && <div className="content-gap" />}

            {detail.summary && (
              <IconHeading icon="pro-institution-intro" title="报告简介" />
            )}
            {detail.summary && (
              <div
                className="content-block"
                dangerouslySetInnerHTML={{
                  __html: detail.summary,
                }}
              />
            )}

            {detail.reportCatalog && detail.reportCatalog.images.length > 0 && (
              <div className="content-gap" />
            )}

            {detail.reportCatalog && detail.reportCatalog.images.length > 0 && (
              <IconHeading icon="pro-catalog" title="报告目录" />
            )}
            {detail.reportCatalog && detail.reportCatalog.images.length > 0 && (
              <div className="report-catalog content-block">
                <img
                  className="report-catalog__main-image"
                  src={
                    detail.reportCatalog.images[
                      detail.reportCatalog.activeIndex
                    ].preview
                  }
                  alt="报告目录"
                />
                <div className="report-catalog__thumbnails">
                  <div
                    onClick={handleLeftClick}
                    onMouseEnter={() => {
                      setArrowLeftColor("#0062ff");
                    }}
                    onMouseLeave={() => {
                      setArrowLeftColor("#C6CBD4");
                    }}
                  >
                    <Icon
                      name="arrow-left-with-circle"
                      size={20}
                      color={arrowLeftColor}
                    />
                  </div>
                  <div
                    className="report-catalog__thumbnails-images"
                    ref={thumbnailsRef}
                  >
                    {detail.reportCatalog.images.map((item, i) => {
                      const activeClass =
                        detail.reportCatalog.activeIndex === i
                          ? "thumbnail--active"
                          : "";

                      return (
                        <img
                          className={activeClass}
                          tabIndex={0}
                          key={i}
                          src={item.thumbnail}
                          alt="报告目录缩略图"
                          onClick={() => updateActiveThumbnail(i)}
                        />
                      );
                    })}
                  </div>
                  <div
                    onClick={handleRightClick}
                    onMouseEnter={() => {
                      setArrowRightColor("#0062ff");
                    }}
                    onMouseLeave={() => {
                      setArrowRightColor("#C6CBD4");
                    }}
                  >
                    <Icon
                      name="arrow-right-with-circle"
                      size={20}
                      color={arrowRightColor}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="content-btn-group">
              {(memberInfo && memberInfo.listVip) || detail.unlocked ? (
                <div
                  className="content-btn-read"
                  onClick={() => {
                    window.open(detail.fullFileUrl, "_target");
                  }}
                >
                  查看报告
                </div>
              ) : (
                <div className="member-private-area">
                  <div className="member-private-area__locked">
                    <img
                      className="member-private-area__icon"
                      src={lockIcon}
                      alt="lock"
                    />
                    <p>尚未解锁</p>
                  </div>
                  {buy ? (
                    <>
                      <div
                        className="member-private-area__btn member-private-area__btn-lock"
                        onClick={() => {
                          lockFn();
                        }}
                      >
                        查看报告
                      </div>
                    </>
                  ) : (
                    <div
                      className="member-private-area__btn member-private-area__btn-buy member-private-area__btn-extra"
                      onClick={() => {
                        buyType(
                          isNewMember,
                          windowExpire,
                          memberInfo.listVip,
                          setDialog,
                          setDiscount
                        );
                      }}
                    >
                      订阅会员，解锁全文
                    </div>
                  )}
                  {buy && (
                    <p className="member-private-area__bottom">
                      当前文章需要 <span>{detail.unlockSpend}</span>{" "}
                      积分解锁，剩余{" "}
                      <span
                        className={!buy || !memberInfo.points ? "un-buy" : ""}
                      >
                        {memberInfo ? memberInfo.points : 0}
                      </span>{" "}
                      积分
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(MemberInboxReport);
