import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./dialog.scss";
import { Dialog } from "../../shared";
import agreeIcon from "../../../styles/images/inbox-agree.png";
import disagreeIcon from "../../../styles/images/inbox-disagree.png";
import tipsIcon from "../../../styles/images/inbox-tips.png";
import correspondenceStore from "../../../stores/correspondenceStore";
import {
  initWxPayCode,
  // debugFn,
  // isWxLogin,
  fetchWxPayStatus,
} from "../../../utils/wx_tools";
import AuthStore from "../../../stores/authStore";
import { fetchMemberInfoApi, unlockMemberApi } from "../../../api/index";
import { buyInboxMemberEvent } from "../../../utils/ga_events";
import checkOrigin from "../member-layout/checkOrigin";
import { onAllImgLoaded } from "../../../utils/tools";
// import { message } from "antd";
import QRCode from "qrcode";

interface Props {
  onSuccess?(): any;
}

// 新用户购买弹窗
const NewBuyDialog: React.FC<Props> = (props: Props) => {
  const { onSuccess = () => {} } = props;
  const { id } = useParams();
  const { fetchMemberInfo, memberInfo } = useContext(AuthStore);
  const {
    dialog,
    setDialog,
    xy,
    // xyText,
    // wxLogin,
    // ntmId,
    noyId,
    setPurchase,
  } = useContext(correspondenceStore);
  const [goods, setGoods] = useState<any>([
    {
      id: noyId,
      info: {},
      desc: "一年会员",
    },
    {
      id: noyId,
      info: {},
      desc: "一年会员",
    },
  ]);
  const [good, setGood] = useState<any>(goods[0]);
  const [show, setShow] = useState<any>(false);
  const [agree, setAgree] = useState<any>(true);
  const [timer, setTimer] = useState<any>(null);
  const purchase_source = checkOrigin();

  const fetchInfo = async () => {
    //!todo: 因为后端接口并发会导致卡死,等后端修复后恢复成promise.all();
    await fetchMemberInfoApi(goods[0].id).then((res: any) => {
      goods[0].src = res.show_image_url;
      goods[0].info = res;
      setGoods(goods);
    });
    await fetchMemberInfoApi(goods[1].id).then((res: any) => {
      goods[1].src = res.show_image_url;
      goods[1].info = res;
      setGoods(goods);
    });
  };

  const updatePayStatus = (data: any) => {
    fetchWxPayStatus(data.orderId).then(async () => {
      if (id) {
        await unlockMemberApi({
          id,
        });
      }
      // 标记是刚购买过的会员
      setPurchase("newMember");
      fetchMemberInfo();
      setDialog("pay-success", true);
      onSuccess();
      buyInboxMemberEvent({
        transaction_id: data.shortId,
        value: data.final_price / 100,
        item_id: data.proProductId,
        item_name: data.proProductName,
        price: data.final_price / 100,
        purchase_source,
        user_id: memberInfo.id,
        user: memberInfo.username,
      });
    });
  };

  useEffect(() => {
    setGood(goods[0]);
    setShow(false);
    if (dialog["new-buy"]) {
      fetchInfo().then(() => {
        onAllImgLoaded(document.querySelector(".new-buy-dialog-1")).then(() => {
          setShow(true);
        });
      });
    } else {
      clearInterval(timer);
    }
  }, [dialog["new-buy"]]);

  useEffect(() => {
    if (dialog["new-buy"] && good.info.price) {
      initWxPayCode(good.id).then(async (data: any) => {
        QRCode.toDataURL(data.codeUrl, { margin: 0 }).then(url => {
          const dom = document.querySelector(".qr-code");
          dom.setAttribute("src", url);
          clearInterval(timer);
          const t = setInterval(() => {
            updatePayStatus(data);
          }, 1000);
          setTimer(t);
        });
      });
    }
  }, [dialog["new-buy"], good.info.price]);

  const onCancel = () => {
    setDialog("new-buy", false);
  };

  return (
    <Dialog
      style={{
        padding: "0",
        borderRadius: "10px",
        backgroundColor: "transparent",
      }}
      visible={dialog["new-buy"]}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "20px", right: "19px" }}
    >
      <div className={`new-buy-dialog-1 ${show && "new-buy-dialog-1--show"}`}>
        <div className="cost-dialog-1__header">
          <img src={good.src} alt="" />
          {/* <div className="new-buy-tap__wrapper">
            <div
              className="new-buy-tap new-buy-tap--1"
              onClick={() => {
                setGood(goods[0]);
              }}
            />
            <div
              className="new-buy-tap new-buy-tap--2"
              onClick={() => {
                setGood(goods[1]);
              }}
            />
          </div> */}
        </div>
        <div className="cost-dialog-1__body">
          <div
            className="cost-dialog-1__code"
            style={good.info.desc ? {} : { marginBottom: "20px" }}
          >
            <div className="code">
              <img className="qr-code" src="" alt="code" />
            </div>
            {good.info.final_price && (
              <div className="info">
                <p>{(good.info.final_price / 100).toFixed(1)}</p>
                <p>微信扫码支付，开通订阅</p>
                <div className="cost-tips">
                  <span onClick={() => () => setAgree(true)}>
                    <img
                      src={agree ? agreeIcon : disagreeIcon}
                      alt="agreeIcon"
                    />
                    我已阅读并同意{" "}
                  </span>
                  <span>
                    <a href={xy} target="_blank">
                      《用户服务协议》
                    </a>
                  </span>
                </div>
              </div>
            )}
          </div>
          {good.info.desc && (
            <div className="cost-intro">
              <div className="cost-intro__title">
                <img src={tipsIcon} alt="agreeIcon" />
                权益详细说明
              </div>
              <div className="cost-intro__desc">
                {good.info.desc
                  .split("\r\n")
                  .map((p: string, index: number) => (
                    <p key={index}>{p}</p>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default observer(NewBuyDialog);
