import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import "./reference-detail.scss";
import { MemberLayout, MemberNav, MemberSidebar } from "../shared/index";
import AuthStore from "../../stores/authStore";
import CorrespondenceStore from "../../stores/correspondenceStore";
import PrivateArea from "../correspondence/PrivateArea";
import descImg from "../../styles/images/member-inbox-desc.png";
import Loading from "../shared/loading/Loading";
// import LoginDialog from "../memberInbox/LoginDialog";
import MyList from "../correspondence/MyList";
import MyDirectory from "../correspondence/MyDirectory";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";

const ReferenceDetail: React.FC = () => {
  const { id } = useParams();
  const { fetchMemberInfo, memberLoading, memberInfo } = useContext(AuthStore);
  const {
    fetchList,
    fetchDetail,
    detail,
    detailLoading,
    scrollChoose,
    activeIndex,
    revertDetail,
    setDialog,
  } = useContext(CorrespondenceStore);

  const [isPreview, setIsPreview] = useState(false);
  const [topTip, setTopTip] = useState("");
  const [isDirectory, setIsDirectory] = useState(false);

  const onScrollEvent = () => {
    if (!memberLoading && !memberInfo) {
      (document.querySelector(
        ".reference-detail__content"
      ) as any).scrollTop = 0;
      setDialog("login", true);
    } else {
      const dom = document.getElementsByClassName(
        "reference-detail__content"
      )[0];
      setIsDirectory(!detailLoading && dom.scrollTop > dom.clientHeight);
      scrollChoose("reference");
    }
  };

  useEffect(() => {
    revertDetail();
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    setTimeout(() => {
      fetchDetail(id);
    }, 500);
  }, [id]);

  useEffect(() => {
    if (!detailLoading) {
      const view = new Viewer(
        document.querySelector(".reference-detail-body"),
        {
          button: false,
          toolbar: false,
          title: false,
          navbar: false,
          initialCoverage: 1,
          zIndex: 10000,
          transition: false,
          viewed(e: any) {
            const {
              target: { viewer },
            } = e;
            // console.log(viewer);
            const now = viewer.index + 1;
            const total = viewer.length;
            setTopTip(`${now}/${total}`);
          },
          show() {
            setIsPreview(true);
          },
          hide() {
            setIsPreview(false);
            setTopTip("");
          },
          ready() {
            document.querySelector(".viewer-container").addEventListener(
              "click",
              (e: any) => {
                e.stopPropagation();
                view.hide();
              },
              false
            );
          },
        }
      );
    }
  }, [detailLoading]);

  return (
    <div className="reference-detail">
      <Helmet>
        <title>{detailLoading ? "" : detail.title} ｜ 机器之心PRO</title>
      </Helmet>
      <MemberLayout>
        <MemberNav
          refreshFn={() => {
            fetchMemberInfo();
            fetchList("refer");
          }}
        />
        <MemberSidebar />
        {/* <LoginDialog /> */}
        <div className="reference-detail__content-wrapper">
          <div
            className="reference-detail__content"
            id="reference-detail-content"
            onScrollCapture={onScrollEvent}
          >
            <MyList type="refer" conName="reference" />
            <div className="reference-detail__info">
              {detailLoading ? (
                <Loading />
              ) : (
                <>
                  {isPreview && (
                    <p className="member-inbox-preview-top">{topTip}</p>
                  )}
                  <div className="reference-detail-header">
                    {detail.title && (
                      <p className="detail-title">{detail.title}</p>
                    )}
                    {detail.date && (
                      <p className="detail-date">{detail.date}</p>
                    )}
                    {detail.summary && (
                      <p className="detail-summary">
                        <img src={descImg} alt="desc" />
                        {detail.summary}
                      </p>
                    )}
                  </div>
                  <div
                    className="reference-detail-body"
                    dangerouslySetInnerHTML={{
                      __html: `${detail.fullContent || detail.previewContent}`,
                    }}
                  />
                  <PrivateArea />
                </>
              )}
            </div>
            {!detailLoading && detail.fullContent && (
              <MyDirectory
                type="reference"
                active={activeIndex}
                style={{ opacity: isDirectory ? 1 : 0 }}
              />
            )}
          </div>
        </div>
      </MemberLayout>
    </div>
  );
};

export default observer(ReferenceDetail);
