import { action, observable } from "mobx";
import { createContext } from "react";

import p1 from "../styles/images/member-inbox--pc/inbox.png";
import p2 from "../styles/images/member-inbox--pc/inbox--active.png";
import n1 from "../styles/images/member-inbox--pc/reference.png";
import n2 from "../styles/images/member-inbox--pc/reference--active.png";
import s1 from "../styles/images/member-inbox--pc/search.png";
import s2 from "../styles/images/member-inbox--pc/search--active.png";

class GlobalStore {
  // 刷新前 是否显示过绑定
  @observable public showBind = false;
  @observable public sidebarList = [
    {
      text: "会员通讯",
      active: false,
      icon: p1,
      activeIcon: p2,
      path: "/inbox",
      hover: false,
    },
    {
      text: "专题解读",
      active: false,
      icon: n1,
      activeIcon: n2,
      path: "/reference",
      hover: false,
    },
    {
      text: "搜索",
      active: false,
      icon: s1,
      activeIcon: s2,
      path: "/member-search",
      hover: false,
    },
  ];

  @action public setSidebarList = (path: string) => {
    this.sidebarList = this.sidebarList.map((item: any) => ({
      ...item,
      active: path.includes(item.path),
    }));
  };

  @action public setSidebarHover = (text: string) => {
    this.sidebarList = this.sidebarList.map((item: any) => ({
      ...item,
      hover: item.text === text ? true : false,
    }));
  };

  @action public resetSidebarHover = () => {
    this.sidebarList = this.sidebarList.map((item: any) => ({
      ...item,
      hover: false,
    }));
  };

  @action public setShowBind = () => {
    this.showBind = true;
  };
}

export default createContext(new GlobalStore());
