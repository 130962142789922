import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";

import "./member-inbox-detail.scss";
import { isWeiXin, urlTranslate } from "../../utils/wx_tools";
import AuthStore from "../../stores/authStore";
import MemberInboxStore from "../../stores/memberInboxStore";
import PrivateArea from "./PrivateArea";
import descImg from "../../styles/images/member-inbox-desc.png";
import Loading from "../shared/loading/Loading";
import backImg from "../../styles/images/member-inbox-back.png";
import PayErrorDialog from "./PayErrorDialog";
import PaySuccessDialog from "./PaySuccessDialog";
import BuyIntegralDialog from "./BuyIntegralDialog";
import CostDialog from "./CostDialog";
import LoginDialog from "./LoginDialog";
import MyDirectory from "./MyDirectory";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import NewBuyDialog from "./NewBuyDialog";
import UserAgreementDialog from "./UserAgreementDialog";
import { isMobile } from "../../utils/tools";

const MemberInboxDetail: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const {
    isIOS,
    memberInfo,
    fetchMemberInfo,
    memberLoading,
    currentUser,
  } = useContext(AuthStore);
  const {
    fetchDetail,
    detail,
    detailLoading,
    setDialog,
    isBack,
    setIsBack,
  } = useContext(MemberInboxStore);

  const [isPreview, setIsPreview] = useState(false);
  const [topTip, setTopTip] = useState("");

  const authFn = () => {
    if (!memberLoading && !memberInfo) {
      window.sessionStorage.setItem("id", id);
      setDialog("login", true);
    } else {
      // 已登录未实名认证
      if (currentUser && !currentUser.mobile_verified) {
        history.replace("/auth");
      } else {
        const xy =
          memberInfo.proMemberLicensedRecordVersion ===
          memberInfo.systemProMemberLicenseVersion;
        // 会员需要判断是否同意最新协议
        if (memberInfo.listVip && !xy) {
          setDialog("user-agreement", true);
        }
      }
    }
  };

  const onScrollEvent = () => {
    if (!detailLoading) {
      const dom = document.getElementsByClassName("member-inbox-detail")[0];
      if (dom && dom.scrollTop > dom.clientHeight * 0.5) {
        setIsBack(true);
      } else {
        setIsBack(false);
      }
    }
  };

  useEffect(() => {
    if (isWeiXin()) {
      fetchMemberInfo();
      if (!(currentUser && !currentUser.mobile_verified)) {
        fetchDetail(id);
        window.sessionStorage.removeItem("login");
        setIsBack(false);
      }
    } else {
      if (isMobile()) {
        history.replace("/inbox-pc");
      } else {
        urlTranslate(history);
      }
    }
  }, []);

  useEffect(() => {
    if (isWeiXin() && !detailLoading) {
      const view = new Viewer(
        document.querySelector(".member-inbox-detail-body"),
        {
          button: false,
          toolbar: false,
          title: false,
          navbar: false,
          initialCoverage: 1,
          zIndex: 10000,
          transition: false,
          viewed(e: any) {
            const {
              target: { viewer },
            } = e;
            // console.log(viewer);
            const now = viewer.index + 1;
            const total = viewer.length;
            setTopTip(`${now}/${total}`);
          },
          show() {
            setIsPreview(true);
          },
          hide() {
            setIsPreview(false);
            setTopTip("");
          },
          ready() {
            document.querySelector(".viewer-container").addEventListener(
              "click",
              (e: any) => {
                e.stopPropagation();
                view.hide();
              },
              false
            );
          },
        }
      );
    }
  }, [detailLoading]);

  useEffect(() => {
    if (!memberLoading) {
      authFn();
    }
  }, [memberLoading]);

  return (
    <div className="member-inbox-detail" onScrollCapture={onScrollEvent}>
      <Helmet>
        <title>{detailLoading ? "" : detail.title} ｜ 机器之心PRO</title>
      </Helmet>
      {!detailLoading && detail.fullContent && <MyDirectory />}
      <NewBuyDialog onSuccess={() => fetchDetail(id)} />
      <CostDialog onSuccess={() => fetchDetail(id)} />
      <BuyIntegralDialog onSuccess={() => fetchDetail(id)} />
      <PayErrorDialog />
      <PaySuccessDialog />
      <LoginDialog />
      <UserAgreementDialog />
      {detailLoading ? (
        <Loading />
      ) : (
        <>
          {isBack && (
            <div
              className="member-inbox-detail-back"
              onClick={() => {
                window.localStorage.removeItem("gangLogin");
                history.replace("/m/inbox");
              }}
            >
              <img src={backImg} alt="back" />
            </div>
          )}
          {isPreview && <p className="member-inbox-preview-top">{topTip}</p>}
          <div className="member-inbox-detail-header">
            {detail.title && <p className="detail-title">{detail.title}</p>}
            {detail.date && <p className="detail-date">{detail.date}</p>}
            {detail.summary && (
              <p className="detail-summary">
                <img src={descImg} alt="desc" />
                {detail.summary}
              </p>
            )}
          </div>

          <div
            className={`member-inbox-detail-body ${
              isIOS ? "member-inbox-detail-body--ios" : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: `${detail.fullContent || detail.previewContent}`,
            }}
          />

          <PrivateArea />
        </>
      )}
    </div>
  );
};

export default observer(MemberInboxDetail);
