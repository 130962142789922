import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import "./bind-info.scss";
import { Form, Input, message } from "antd";
import authStore from "../../stores/authStore";
import memberInboxStore from "../../stores/memberInboxStore";
import { updateInfoApi } from "../../api/index";
import SyncedLogo from "../../styles/images/logo-synced-black.png";
import ProImg from "../../styles/images/pro.svg";

const BindInfo: React.FC = () => {
  const [form] = Form.useForm();
  const { memberInfo, fetchMemberInfo } = useContext(authStore);
  const { onSendCode2, time } = useContext(memberInboxStore);
  const [isComplete, setIsComplete] = useState(false);
  const id = window.sessionStorage.getItem("id");

  const onFieldsChange = () => {
    const values: any = form.getFieldsValue();
    const { mobile, phone_verify_code } = values;
    setIsComplete(
      !!mobile &&
        !!phone_verify_code &&
        form.getFieldsError().filter(({ errors }) => errors.length).length === 0
    );
  };

  const onFinish = async values => {
    if (isComplete) {
      setIsComplete(false);
      try {
        await updateInfoApi({ user: values });
        message.open({
          type: "success",
          duration: 3,
          className: "ant-toast",
          content: "绑定成功",
        });
        window.location.replace(id ? `/m/inbox/${id}` : `/m/inbox`);
      } catch (err) {
        if (err.error.message && err.error.message.length > 0) {
          const errMessage = err.error.message.toString();
          message.error(
            errMessage === "手机已经被使用"
              ? "该手机号已存在，请使用新手机号绑定"
              : errMessage === "手机验证码错误"
              ? "验证码错误，请输入正确的验证码"
              : errMessage === "手机已经被使用,手机验证码错误"
              ? "该手机号已存在或验证码输入错误，请重新输入"
              : errMessage
          );
        }
        setIsComplete(true);
      }
    }
  };

  useEffect(() => {
    fetchMemberInfo();
  }, []);

  useEffect(() => {
    if (memberInfo && memberInfo.mobile) {
      memberInfo.email
        ? window.location.replace(id ? `/m/inbox/${id}` : `/m/inbox`)
        : setIsComplete(true);
    }
  }, [memberInfo]);

  return (
    <div className="member-inbox-bind-info-layout">
      <Helmet>
        <title>绑定信息 ｜ 机器之心PRO</title>
      </Helmet>
      <div className="bind-info__header">
        <Link to="/">
          <img src={SyncedLogo} alt="Synced" />
          <img src={ProImg} />
        </Link>
      </div>
      <div className="bind-info__content">
        <p className="bind-info__content__title">绑定账号信息</p>
        <div className="bind-info__content__desc">
          该信息用于实名认证和防止账号丢失方便找回，建议您绑定手机号码
        </div>
        {memberInfo && (
          <Form
            className="bind-info__form"
            initialValues={memberInfo}
            autoComplete="off"
            form={form}
            onFieldsChange={onFieldsChange}
            onFinish={values => onFinish(values)}
          >
            <Form.Item
              rules={[
                { required: true, message: "请输入手机号" },
                {
                  pattern: /^1[3456789]\d{9}$/,
                  message: "请输入正确的手机号",
                },
              ]}
              label="手机号"
              name="mobile"
            >
              <Input disabled={memberInfo.mobile} placeholder="请输入手机号" />
            </Form.Item>
            <div className="bind-info__form-code">
              <Form.Item
                hasFeedback
                rules={[{ required: true, message: "请输入正确的验证码" }]}
                name="phone_verify_code"
              >
                <Input autoComplete="off" placeholder="验证码" />
              </Form.Item>
              <div
                className={`bind-info__form-send-code ${time > 0 &&
                  "bind-info__form-send-code--time"}`}
                onClick={() => {
                  if (time <= 0) {
                    const values: any = form.getFieldsValue();
                    const { mobile } = values;
                    onSendCode2({ mobile });
                  }
                }}
              >
                {time > 0 ? `${time}s 后重新发送` : "发送验证码"}
              </div>
            </div>
            <div
              className={`bind-info__form-btn ${!isComplete &&
                "bind-info__form-btn--opacity"}`}
              onClick={() => form.submit()}
            >
              提交
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default observer(BindInfo);
