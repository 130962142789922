import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./private-area.scss";
import AuthStore from "../../stores/authStore";
import CorrespondenceStore from "../../stores/correspondenceStore";
import { message } from "antd";
import { unlockMemberApi } from "../../api/index";
import lockIcon from "../../styles/images/inbox__lock-icon.png";
import { readInboxArticleEvent } from "../../utils/ga_events";
import buyType from "./buyType";

const PrivateArea: React.FC = () => {
  const { id } = useParams();
  const { memberInfo, fetchMemberInfo, isNewMember, windowExpire } = useContext(
    AuthStore
  );
  const { detail, fetchDetail, setDialog, setIsBack, setDiscount } = useContext(
    CorrespondenceStore
  );
  const [buy, setBuy] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (memberInfo && detail) {
      if (memberInfo.points >= detail.unlockSpend) {
        setBuy(true);
      }
    }
  }, [memberInfo, detail]);

  useEffect(() => {
    if (memberInfo) {
      const obj = {
        user_id: memberInfo.id,
        user: memberInfo.username,
        article_id: detail.id,
        article_type: "member_only_content",
        article: detail.title,
        article_purchased: !!detail.fullContent,
      };
      readInboxArticleEvent(obj);
      console.log(obj);
    }
  }, [memberInfo]);

  const lockFn = async () => {
    if (disable) {
      return false;
    }
    setDisable(true);
    await unlockMemberApi({
      id,
    });
    await fetchMemberInfo();
    fetchDetail(id);
    message.open({
      type: "success",
      duration: 3,
      className: "ant-toast",
      content: "已解锁",
    });
    setIsBack(false);
    setDisable(false);
  };

  return !detail.fullContent ? (
    <div className="private-area">
      <div className="private-area__locked">
        <img className="private-area__icon" src={lockIcon} alt="lock" />
        <p>尚未解锁</p>
      </div>
      {buy ? (
        <>
          <div
            className="private-area__btn private-area__btn-lock"
            onClick={() => lockFn()}
          >
            解锁全文
          </div>
        </>
      ) : (
        <>
          {/* 会员+会员过期30天内 可积分解锁 */}
          {memberInfo &&
          (memberInfo.listVip || (!memberInfo.listVip && windowExpire)) ? (
            <>
              <div
                className="private-area__btn private-area__btn-buy"
                onClick={() => setDialog("integral", true)}
              >
                购买积分，解锁全文
              </div>
            </>
          ) : (
            <>
              <div
                className="private-area__btn private-area__btn-buy private-area__btn-extra"
                onClick={() => {
                  buyType(
                    isNewMember,
                    windowExpire,
                    memberInfo.listVip,
                    setDialog,
                    setDiscount
                  );
                }}
              >
                订阅会员，解锁全文
              </div>
            </>
          )}
        </>
      )}
      <p className="private-area__bottom">
        当前文章需要 <span>{detail.unlockSpend}</span> 积分解锁，剩余{" "}
        <span className={!buy || !memberInfo.points ? "un-buy" : ""}>
          {memberInfo ? memberInfo.points : 0}
        </span>{" "}
        积分
      </p>
    </div>
  ) : (
    <></>
  );
};

export default observer(PrivateArea);
