import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";

import "./member-search-list.scss";
import { Input } from "antd";
// import { useHistory } from "react-router-dom";
// import authStore from "../../stores/authStore";
import memberSearchStore from "../../stores/memberSearchStore";
import MemberCard from "./MemberCard";
import Loading from "../shared/loading/Loading";
import searchIcon from "../../styles/images/member-inbox--pc/search-input.png";
import searchEmptyIcon from "../../styles/images/member-inbox--pc/search-empty.png";

const MemberSearchList: React.FC = () => {
  // const history = useHistory();
  // const { memberInfo, memberLoading } = useContext(authStore);
  const {
    list,
    pagination,
    loading,
    fetchList,
    nextPage,
    revert,
    keyword,
    setKeyword,
    id,
    setId,
  } = useContext(memberSearchStore);
  const [listHeight, setListHeight] = useState<any>("0");
  const [activeId, setActiveId] = useState("");
  const container: any = document.querySelector(`.member-search__content`);

  const onScrollEvent = () => {
    if (!loading && pagination.hasNextPage) {
      const dom = document.getElementsByClassName(
        "member-search__list--wrapper"
      )[0];
      if (
        dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
        list.length < pagination.total
      ) {
        nextPage(keyword);
      }
    }
  };

  useEffect(() => {
    setActiveId("");
  }, []);

  useEffect(() => {
    id && setActiveId(id);
  }, [window.innerWidth, id]);

  useEffect(() => {
    container && setListHeight(`${container.clientHeight - 22}px`);
  }, [container]);

  return (
    <div
      className={`${id ? "member-search-my-list" : "member-search__list"}`}
      style={id ? { maxHeight: listHeight } : {}}
    >
      <div className="member-search__list--search">
        <Input
          prefix={<img src={searchIcon} alt="icon" />}
          allowClear
          placeholder="输入关键词进行搜索"
          defaultValue={keyword}
          onChange={(e: React.BaseSyntheticEvent) => {
            if (e.target.value === "") {
              revert();
              setKeyword("");
              setId("");
            }
          }}
          onPressEnter={(e: React.BaseSyntheticEvent) => {
            setKeyword(e.target.value);
            fetchList(e.target.value);
            // TODO: 本地用通讯数据看样式
            // fetchList("comm");
          }}
        />
      </div>
      <div
        className="member-search__list--wrapper"
        onScrollCapture={onScrollEvent}
      >
        {list &&
          list.length > 0 &&
          list.map((item: any, index: number) => (
            <MemberCard
              key={index}
              model={item.source}
              activeId={activeId}
              hasTags
            />
          ))}
        {!loading && keyword && list && list.length === 0 && (
          <div className="member-search__empty">
            <img src={searchEmptyIcon} alt="empty" />
            <p>暂未检索到相关内容</p>
          </div>
        )}
        {loading && <Loading />}
      </div>
    </div>
  );
};

export default observer(MemberSearchList);
