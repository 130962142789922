import React, { useContext } from "react";
import { observer } from "mobx-react";

import "./style.scss";
import authStore from "../../../stores/authStore";
import correspondenceStore from "../../../stores/correspondenceStore";
import fireImg from "../../../styles/images/member-inbox--pc/new-fire.png";

const MemberTips: React.FC = () => {
  const { memberInfo, isNewMember, windowExpire } = useContext(authStore);
  const { setDialog, setDiscount } = useContext(correspondenceStore);

  const initBtn = (text?: string) => {
    const _text = text || "立即购买";
    return <div className="buy-btn">{_text}</div>;
  };

  // console.log(memberInfo, "memberInfo");

  return memberInfo ? (
    <div className="member-tips">
      <div
        className="member-inbox__expired"
        onClick={() => {
          if (isNewMember) {
            //新用户:从未购买过会员
            setDialog("new-buy", true);
          } else if (
            (memberInfo.listVip &&
              memberInfo.dueExpiredDay > 0 &&
              memberInfo.dueExpiredDay <= 30) ||
            (!memberInfo.listVip && windowExpire)
          ) {
            //即将到期、会员过期30天内windowExpire
            setDiscount(true); // 设置是否折扣购买
            setDialog("cost", true);
          }
        }}
      >
        {/* 新用户 */}
        {isNewMember && (
          <div className="new-member">
            <img src={fireImg} alt="fire" />
            新用户专享·首购特惠，单期低至￥6.4！{initBtn("立即订阅")}
          </div>
        )}
        {/* 即将到期 */}
        {memberInfo.listVip &&
          memberInfo.dueExpiredDay > 0 &&
          memberInfo.dueExpiredDay <= 30 && (
            <div className="old-member">
              <p className="deadline">
                您的会员剩余 <span>{memberInfo.dueExpiredDay}</span> 天到期，
              </p>
              <p>会员期内续费立省￥210，单期低至￥6.4！</p>
              {initBtn("立即续订")}
            </div>
          )}
        {/* 会员过期30天内windowExpire */}
        {!memberInfo.listVip && windowExpire && (
          <div className="expire-member">
            <p>
              <span>{memberInfo.baseExpiredAt}</span>
              前续费立省￥210！单期低至￥6.4！
            </p>
            <p>剩余积分在此期间仍可使用，过期后清零</p>
            {initBtn("立即续订")}
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default observer(MemberTips);
