import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";

import "./member-inbox-interpretation.scss";
import { isWeiXin, urlTranslate } from "../../utils/wx_tools";
import AuthStore from "../../stores/authStore";
import MemberInboxStore from "../../stores/memberInboxStore";
// import descImg from "../../styles/images/member-inbox-desc.png";
import backImg from "../../styles/images/member-inbox-back.png";
import PayErrorDialog from "./PayErrorDialog";
import PaySuccessDialog from "./PaySuccessDialog";
import BuyIntegralDialog from "./BuyIntegralDialog";
import CostDialog from "./CostDialog";
import LoginDialog from "./LoginDialog";
import MyDirectory from "./MyDirectory";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import NewBuyDialog from "./NewBuyDialog";
import { Loading } from "../shared";
import lockIcon from "../../styles/images/inbox__lock-icon.png";
import { unlockMemberApi } from "../../api/index";
import { message } from "antd";
import buyType from "./buyType";
import { isMobile } from "../../utils/tools";

const MemberInboxInterpretation: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const {
    isIOS,
    memberInfo,
    fetchMemberInfo,
    memberLoading,
    currentUser,
    isNewMember,
    windowExpire,
  } = useContext(AuthStore);
  const {
    fetchDetail,
    detail,
    detailLoading,
    setDialog,
    isBack,
    setIsBack,
    setDiscount,
  } = useContext(MemberInboxStore);

  const [isPreview, setIsPreview] = useState(false);
  const [topTip, setTopTip] = useState("");
  const [buy, setBuy] = useState(false);
  const [disable, setDisable] = useState(false);

  const authFn = () => {
    if (!memberLoading && !memberInfo) {
      window.sessionStorage.setItem("id", id);
      setDialog("login", true);
    }
    // 已登录未实名认证
    currentUser && !currentUser.mobile_verified && history.replace("/auth");
  };

  const onScrollEvent = () => {
    if (!detailLoading) {
      const dom = document.getElementsByClassName(
        "member-inbox-interpretation"
      )[0];
      if (dom && dom.scrollTop > dom.clientHeight * 0.5) {
        setIsBack(true);
      } else {
        setIsBack(false);
      }
    }
  };

  useEffect(() => {
    if (isWeiXin()) {
      fetchMemberInfo();
      if (!(currentUser && !currentUser.mobile_verified)) {
        fetchDetail(id);
        window.sessionStorage.removeItem("login");
        setIsBack(false);
      }
    } else {
      if (isMobile()) {
        history.replace("/inbox-pc");
      } else {
        urlTranslate(history);
      }
    }
  }, []);

  useEffect(() => {
    if (isWeiXin() && !detailLoading) {
      const view = new Viewer(
        document.querySelector(".member-inbox-detail-body"),
        {
          button: false,
          toolbar: false,
          title: false,
          navbar: false,
          initialCoverage: 1,
          zIndex: 10000,
          transition: false,
          viewed(e: any) {
            const {
              target: { viewer },
            } = e;
            // console.log(viewer);
            const now = viewer.index + 1;
            const total = viewer.length;
            setTopTip(`${now}/${total}`);
          },
          show() {
            setIsPreview(true);
          },
          hide() {
            setIsPreview(false);
            setTopTip("");
          },
          ready() {
            document.querySelector(".viewer-container").addEventListener(
              "click",
              (e: any) => {
                e.stopPropagation();
                view.hide();
              },
              false
            );
          },
        }
      );
    }
  }, [detailLoading]);

  useEffect(() => {
    if (memberInfo && detail) {
      if (memberInfo.points >= detail.unlockSpend) {
        setBuy(true);
      }
    }
  }, [memberInfo, detail]);

  useEffect(() => {
    if (!memberLoading) {
      authFn();
    }
  }, [memberLoading]);

  const lockFn = async () => {
    if (disable) {
      return false;
    }
    setDisable(true);
    await unlockMemberApi({
      id,
    });
    await fetchMemberInfo();
    fetchDetail(id);
    message.open({
      type: "success",
      duration: 3,
      className: "ant-toast",
      content: "已解锁",
    });
    setDisable(false);
  };

  return (
    <div
      className="member-inbox-interpretation"
      onScrollCapture={onScrollEvent}
    >
      <Helmet>
        <title>{detailLoading ? "" : detail.title} ｜ 机器之心PRO</title>
      </Helmet>
      {!detailLoading && detail.fullContent && <MyDirectory />}
      <NewBuyDialog onSuccess={() => fetchDetail(id)} />
      <CostDialog onSuccess={() => fetchDetail(id)} />
      <BuyIntegralDialog onSuccess={() => fetchDetail(id)} />
      <PayErrorDialog />
      <PaySuccessDialog />
      <LoginDialog />
      {detailLoading ? (
        <Loading />
      ) : (
        <>
          {isBack && (
            <div
              className="member-inbox-detail-back"
              onClick={() => {
                window.localStorage.removeItem("gangLogin");
                history.replace("/m/inbox");
              }}
            >
              <img src={backImg} alt="back" />
            </div>
          )}
          {isPreview && <p className="member-inbox-preview-top">{topTip}</p>}
          <div className="member-inbox-detail-header">
            {detail.title && <p className="detail-title">{detail.title}</p>}
            {detail.date && <p className="detail-date">{detail.date}</p>}
            {/* {detail.summary && (
              <p className="detail-summary">
                <img src={descImg} alt="desc" />
                {detail.summary}
              </p>
            )} */}
          </div>
          <div
            className={`member-inbox-detail-body ${
              isIOS ? "member-inbox-detail-body--ios" : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: `${detail.fullContent || detail.previewContent}`,
            }}
          />
          <div className="content-btn-group">
            {(memberInfo && memberInfo.listVip) || detail.unlocked ? null : (
              <div className="member-private-area">
                <div className="member-private-area__locked">
                  <img
                    className="member-private-area__icon"
                    src={lockIcon}
                    alt="lock"
                  />
                  <p>尚未解锁</p>
                </div>
                {buy ? (
                  <>
                    <div
                      className="member-private-area__btn member-private-area__btn-lock"
                      onClick={() => {
                        lockFn();
                      }}
                    >
                      阅读全文
                    </div>
                  </>
                ) : (
                  <div
                    className="member-private-area__btn member-private-area__btn-buy member-private-area__btn-extra"
                    onClick={() => {
                      buyType(
                        isNewMember,
                        windowExpire,
                        memberInfo.listVip,
                        setDialog,
                        setDiscount
                      );
                    }}
                  >
                    订阅会员，解锁全文
                  </div>
                )}
                {buy && (
                  <p className="member-private-area__bottom">
                    当前文章需要 <span>{detail.unlockSpend}</span>{" "}
                    积分解锁，剩余{" "}
                    <span
                      className={!buy || !memberInfo.points ? "un-buy" : ""}
                    >
                      {memberInfo ? memberInfo.points : 0}
                    </span>{" "}
                    积分
                  </p>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(MemberInboxInterpretation);
