import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import lockIcon from "../../styles/images/lock-icon.png";
import pdfImg from "../../styles/images/inbox-pdf.svg";

interface Props {
  model: any;
  hasTags?: boolean;
  type: string; //通讯:comm, 参考：refer
  onlyTitle?: boolean; //第一行只有title，无其他内容
  activeId?: string; //选中高亮
}

const CorrespondenceCard: React.FC<Props> = (props: Props) => {
  const {
    model = {},
    hasTags = false,
    type,
    onlyTitle = false,
    activeId,
  } = props;
  const history = useHistory();

  useEffect(() => {
    // api
  }, []);

  return (
    <div
      className={`correspondence-card ${activeId === model.id &&
        "correspondence-card--active"}`}
      onClick={() => {
        if (type === "comm") {
          history.push(`/inbox/${model.id}`);
        } else {
          if (model.category && model.category === "report") {
            history.push(`/report/${model.id}`);
            // } else if (model.category && model.category === "interpretation") {
            //   history.push(`/inbox-interpretation/${model.id}`);
          } else {
            history.push(`/reference/${model.id}`);
          }
        }
      }}
    >
      <div className="correspondence-card__info">
        <div
          className={`correspondence-card__title ${onlyTitle &&
            "correspondence-card__title--only"}`}
        >
          <p dangerouslySetInnerHTML={{ __html: model.title }} />
          {!onlyTitle && !model.unlocked && <img src={lockIcon} alt="lock" />}
        </div>
        {!onlyTitle && (
          <p className="correspondence-card__time">{model.publishedTime}</p>
        )}
      </div>
      {model.summary && (
        <p>
          {model.category && model.category === "report" && (
            <img src={pdfImg} alt="pdf" />
          )}
          {model.summary}
        </p>
      )}
      {hasTags && model.hotTags && model.hotTags.length > 0 && (
        <div className="correspondence-card__tags">
          {model.hotTags.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(CorrespondenceCard);
