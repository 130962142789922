import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";

import "./member-search-detail.scss";
import AuthStore from "../../stores/authStore";
import CorrespondenceStore from "../../stores/correspondenceStore";
import memberSearchStore from "../../stores/memberSearchStore";
import PrivateArea from "../correspondence/PrivateArea";
import descImg from "../../styles/images/member-inbox-desc.png";
import Loading from "../shared/loading/Loading";
// import LoginDialog from "../memberInbox/LoginDialog";
import MemberSearchList from "./MemberSearchList";
import MyDirectory from "../correspondence/MyDirectory";
import ReferenceReportInfo from "../reference/ReferenceReportInfo";
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";

const MemberSearchDetail: React.FC = () => {
  const { memberLoading, memberInfo } = useContext(AuthStore);
  const {
    fetchDetail,
    detail,
    detailLoading,
    scrollChoose,
    activeIndex,
    revertDetail,
    setDialog,
  } = useContext(CorrespondenceStore);
  const { id, detailType } = useContext(memberSearchStore);

  const [isPreview, setIsPreview] = useState(false);
  const [topTip, setTopTip] = useState("");
  const [isDirectory, setIsDirectory] = useState(false);

  const onScrollEvent = () => {
    if (!memberLoading && !memberInfo) {
      (document.querySelector(
        ".member-search-detail__content"
      ) as any).scrollTop = 0;
      setDialog("login", true);
    } else {
      const dom = document.getElementsByClassName(
        "member-search-detail__content"
      )[0];
      setIsDirectory(!detailLoading && dom.scrollTop > dom.clientHeight);
      scrollChoose("member-search");
    }
  };

  useEffect(() => {
    revertDetail();
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    setTimeout(() => {
      id && fetchDetail(id);
    }, 500);
  }, [id]);

  useEffect(() => {
    if (!detailLoading && (!detailType || detailType !== "report")) {
      const view = new Viewer(
        document.querySelector(".member-search-detail-body"),
        {
          button: false,
          toolbar: false,
          title: false,
          navbar: false,
          initialCoverage: 1,
          zIndex: 10000,
          transition: false,
          viewed(e: any) {
            const {
              target: { viewer },
            } = e;
            // console.log(viewer);
            const now = viewer.index + 1;
            const total = viewer.length;
            setTopTip(`${now}/${total}`);
          },
          show() {
            setIsPreview(true);
          },
          hide() {
            setIsPreview(false);
            setTopTip("");
          },
          ready() {
            document.querySelector(".viewer-container").addEventListener(
              "click",
              (e: any) => {
                e.stopPropagation();
                view.hide();
              },
              false
            );
          },
        }
      );
    }
  }, [detailLoading]);

  return (
    <div className="member-search-detail__content-wrapper">
      <div
        className="member-search-detail__content"
        id="member-search-detail-content"
        onScrollCapture={onScrollEvent}
      >
        <MemberSearchList />
        {detailType && detailType === "report" ? (
          <ReferenceReportInfo />
        ) : (
          <>
            <div className="member-search-detail__info">
              {detailLoading ? (
                <Loading />
              ) : (
                <>
                  {isPreview && (
                    <p className="member-inbox-preview-top">{topTip}</p>
                  )}
                  <div className="member-search-detail-header">
                    {detail.title && (
                      <p className="detail-title">{detail.title}</p>
                    )}
                    {detail.date && (
                      <p className="detail-date">{detail.date}</p>
                    )}
                    {detail.summary && (
                      <p className="detail-summary">
                        <img src={descImg} alt="desc" />
                        {detail.summary}
                      </p>
                    )}
                  </div>
                  <div
                    className="member-search-detail-body"
                    dangerouslySetInnerHTML={{
                      __html: `${detail.fullContent || detail.previewContent}`,
                    }}
                  />
                  <PrivateArea />
                </>
              )}
            </div>
            {!detailLoading && detail.fullContent && (
              <MyDirectory
                type="member-search"
                active={activeIndex}
                style={{ opacity: isDirectory ? 1 : 0 }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default observer(MemberSearchDetail);
