import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import { MemberTips } from "../index";
import authStore from "../../../stores/authStore";
import correspondenceStore from "../../../stores/correspondenceStore";
import globalStore from "../../../stores/globalStore";
import { isMobile, scanCb, removeQueryParams } from "../../../utils/tools";
import { urlTranslate } from "../../../utils/wx_tools";
// import checkOrigin from "./checkOrigin";

interface Props {
  children: React.ReactNode;
}

const MemberLayout: React.FC = (props: Props) => {
  const { children } = props;
  const history = useHistory();
  const { memberInfo, currentUser, memberLoading } = useContext(authStore);
  const { setDialog, dialog } = useContext(correspondenceStore);
  const { showBind, setShowBind } = useContext(globalStore);

  useEffect(() => {
    if (isMobile()) {
      urlTranslate(history);
    } else {
      // 已登录
      if (currentUser && !dialog.login) {
        // 实名认证
        if (!currentUser.mobile_verified) {
          setDialog("login", true, { type: "sm-rz" });
        } else {
          const res = sessionStorage.getItem("refresh");
          // 因燕子功能变动 （未绑定微信则一直弹） 这里其实无实质用途 只为去除 refresh
          if (res === "page") {
            sessionStorage.removeItem("refresh");
            console.log(currentUser);
          }
          // 合并账号
          scanCb(
            () => {
              setDialog("wx-bind", true, { error: true });
            },
            () => {
              const href = removeQueryParams(location.href, ["error"]);
              (window as any).history.replaceState({ path: href }, "", href);
            },
            () => {
              // 绑定微信
              if (!currentUser.wechat_bind && !showBind) {
                setDialog("wx-bind", true);
                setShowBind();
              }
            }
          );
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   if (!memberLoading) {
  //     if (memberInfo) {
  //       checkOrigin(
  //         () => setDialog("new-buy", true),
  //         () => setDialog("integral", true),
  //         () => {
  //           //年度订阅空降
  //           setDiscount(false); // 默认展示全价购买
  //           setDialog("cost", true);
  //         },
  //         () => {
  //           //会员续费空降
  //           setDiscount(true); // 默认展示折扣购买
  //           setDialog("cost", true);
  //         }
  //       );
  //     }
  //   }
  // }, [memberLoading]);

  return (
    <div className="member-layout">
      <div className="member-layout__header">
        {children[0]}
        <MemberTips />
      </div>
      <div
        className="member-layout__body"
        onClickCapture={(e: React.BaseSyntheticEvent) => {
          if (!memberLoading && !memberInfo) {
            setDialog("login", true);
            e.stopPropagation();
          }
        }}
      >
        <div className="member-layout__aside">{children[1]}</div>
        <div className="member-layout__main">{children[2]}</div>
      </div>
    </div>
  );
};

export default observer(MemberLayout);
