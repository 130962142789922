import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { MemberLayout, MemberNav, MemberSidebar } from "../shared/index";
import AuthStore from "../../stores/authStore";
import CorrespondenceStore from "../../stores/correspondenceStore";
import bookImg from "../../styles/images/correspondence-book.png";
import CorrespondenceCard from "../correspondence/CorrespondenceCard";
import Loading from "../shared/loading/Loading";

const Reference: React.FC = () => {
  const { fetchMemberInfo, memberLoading, memberInfo } = useContext(AuthStore);
  const {
    list,
    pagination,
    loading,
    fetchList,
    nextPage,
    revert,
    setDialog,
  } = useContext(CorrespondenceStore);

  const onScrollEvent = () => {
    if (!memberLoading && !memberInfo) {
      (document.querySelector(".reference__content") as any).scrollTop = 0;
      setDialog("login", true);
    } else {
      if (!loading && pagination.hasNextPage) {
        const dom = document.getElementsByClassName("reference__content")[0];
        if (
          dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
          list.length < pagination.total
        ) {
          nextPage("refer");
        }
      }
    }
  };

  useEffect(() => {
    revert();
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    setTimeout(() => {
      fetchList("refer"); //参考
      window.sessionStorage.removeItem("login");
      window.sessionStorage.removeItem("pathname");
    }, 500);
  }, []);

  return (
    <div className="reference">
      <Helmet>
        <title>专题解读 ｜ 机器之心PRO</title>
      </Helmet>
      <MemberLayout>
        <MemberNav
          refreshFn={() => {
            fetchMemberInfo();
            fetchList("refer");
          }}
        />
        <MemberSidebar />
        <div className="reference__content-wrapper">
          <div
            className="reference__content"
            id="message-content"
            onScrollCapture={onScrollEvent}
          >
            <div className="reference__list">
              {list &&
                list.length > 0 &&
                list.map((item: any, index: number) => (
                  <CorrespondenceCard key={index} model={item} type="refer" />
                ))}
              {loading && <Loading />}
            </div>
          </div>
          <div className="reference__blank">
            <img src={bookImg} alt="book" />
            <p>
              一份让您从此不再担心因业务繁忙而错失 AI &
              Robotics赛道良机的业内通讯
            </p>
          </div>
        </div>
      </MemberLayout>
    </div>
  );
};

export default observer(Reference);
