import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../../shared";
import correspondenceStore from "../../../stores/correspondenceStore";
import closeImg from "../../../styles/images/member-inbox--pc/close.png";
import errorImg from "../../../styles/images/member-inbox--pc/concat-error.png";
import refreshIcon from "../../../styles/images/member-inbox--pc/refresh-icon.png";
import { initWxCode } from "../../../utils/wx_tools";

const WxBindDialog = () => {
  const { dialog, setDialog } = useContext(correspondenceStore);
  const onCancel = () => setDialog("wx-bind", false);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const url = location.origin.replace("pro.", "") + "/setting/wechat_merge";

  const initCode = () => {
    initWxCode();
  };

  useEffect(() => {
    if (dialog["wx-bind"]) {
      if (dialog.params.error) {
        setError(true);
      } else {
        initCode();
      }
    }
  }, [dialog["wx-bind"]]);

  const retryScanCode = () => {
    setError(false);
    setIsOpen(false);
    initCode();
  };

  return (
    <Dialog
      visible={dialog["wx-bind"]}
      style={{ padding: 0, border: 0 }}
      onCancel={onCancel}
      layerClose={false}
      closeIcon={closeImg}
      closeStyle={{
        top: "13px",
        right: "20px",
        width: "18px",
      }}
    >
      {error ? (
        <div className="concat-account__dialog">
          <img className="top" src={errorImg} alt="error" />
          <p className="p1">当前微信已与其他账号绑定</p>
          {/* <p className="p2">请重新更换微信绑定，或者合并账号</p> */}
          <div className="handle">
            <div className="btn1" onClick={() => retryScanCode()}>
              重新扫码
            </div>
            <div
              className="btn2"
              onClick={() => {
                setIsOpen(true);
                window.open(url);
              }}
            >
              合并账号
            </div>
          </div>
          {isOpen && (
            <p className="p3">
              <img src={refreshIcon} alt="icon" />
              若已完成账号合并，点此
              <span onClick={() => location.reload()}>刷新页面</span>
              重新进入收件箱，
            </p>
          )}
        </div>
      ) : (
        <div className="wx-bind__dialog">
          <p className="p1">
            扫一扫完成<span>微信绑定</span>
          </p>
          <p className="p2">绑定微信，在移动端随时阅读会员内容</p>
          <div id="code-node" className="qr-code" />
        </div>
      )}
    </Dialog>
  );
};

export default observer(WxBindDialog);
