import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { Form, Input, Checkbox, Button, Select, message } from "antd";

import "./dialog.scss";
import { Dialog } from "../../shared";
import { trackLoginEvent, trackSignupEvent } from "../../../utils/ga_events";
import { login, register, mobileVerified } from "../../../api";
import authStore from "../../../stores/authStore";
import correspondenceStore from "../../../stores/correspondenceStore";
import { forceLogout } from "../../../utils/tools";
import { initWxCode } from "../../../utils/wx_tools";
import closeImg from "../../../styles/images/member-inbox--pc/close.png";
import logoImg from "../../../styles/images/member-inbox--pc/dialog-logo.png";
import accountImg from "../../../styles/images/member-inbox--pc/meth-account.png";
import codeImg from "../../../styles/images/member-inbox--pc/meth-code.png";
import forgetIcon from "../../../styles/images/forget-icon.svg";
import tipsIcon from "../../../styles/images/member-inbox--pc/tips-icon.svg";

const loginDialog = () => {
  const history = useHistory();
  const url = window.location.pathname;
  const [form] = Form.useForm();
  const { onSendCode, onAuthSendCode, time } = useContext(authStore);
  const { dialog, setDialog } = useContext(correspondenceStore);
  type Types = "code" | "account" | "sm-rz";
  const _type = dialog.params.type || "code";
  const [type, setType] = useState<Types>(_type);

  const [isLogin, setIsLogin] = useState(true); //true:登录,false:注册
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [profession, setProfession] = useState("");
  const [workingYears, setWorkingYears] = useState("");
  const [accountSpan, setAccountSpan] = useState(false);
  const [passwordSpan, setPasswordSpan] = useState(false);
  const [nameSpan, setNameSpan] = useState(false);
  const [codeSpan, setCodeSpan] = useState(false);
  const [professionSpan, setProfessionSpan] = useState(false);
  const [workingYearsSpan, setWorkingYearsSpan] = useState(false);

  const styles = {
    code: {
      height: "unset",
    },
    account: {
      height: "unset",
    },
    "sm-rz": {
      height: "unset",
    },
  };

  const initCode = (type: Types) => {
    if (type === "code") {
      initWxCode();
    }
  };

  let captcha;
  const getInstance = instance => {
    captcha = instance;
  };

  const sendDecorator = async () => {
    const mobile = form.getFieldValue("mobile");
    if (!mobile || !/^1[3456789]\d{9}$/.test(mobile)) {
      message.error("请输入正确的手机号码");
    } else {
      (document.querySelector("#captcha-button") as any).click();
    }
  };

  const captchaVerifyCallback = async captchaVerifyParam => {
    console.log("captchaVerifyCallback触发");
    let result: any = {};
    let error = false;
    try {
      const values: any = form.getFieldsValue();
      const { mobile } = values;
      if (type === "sm-rz") {
        // 实名认证，验证码接口
        result = await onAuthSendCode({ captchaVerifyParam, mobile });
      } else {
        result = await onSendCode({ captchaVerifyParam, mobile });
      }
    } catch (e) {
      error = e;
    }
    console.log("result:", result);

    if (error) {
      captcha.refresh();
      captcha.hide();
    } else {
      return {
        captchaResult: result.captchaResult, // 验证码验证是否通过，boolean类型，必选 result.captchaVerifyResult
        bizResult: result.bizResult, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      };
    }
  };

  // 验证通过后调用
  const onBizResultCallback = bizResult => {
    console.log("bizResult:", bizResult);
  };

  // 登录/注册切换，清空数据
  const revertAccount = () => {
    setAccount("");
    setPassword("");
    setName("");
    setCode("");
    setProfession("");
    setWorkingYears("");
    setAccountSpan(false);
    setPasswordSpan(false);
    setNameSpan(false);
    setCodeSpan(false);
    setProfessionSpan(false);
    setWorkingYearsSpan(false);
    form.resetFields();
  };

  // 登录
  const onLogin = async (data: any) => {
    await login(data, async response => {
      if (response.statusCode === 401) {
        message.error(response.body || "登录失败");
        return;
      }
      trackLoginEvent("Password");
      if (response.statusCode === 200) {
        if (response.body.user && !!response.body.user.mobile_verified) {
          message.success("登录成功");
          window.sessionStorage.setItem("refresh", "page");
          window.location.reload();
          // 已实名认证，关闭弹窗
          // !刷新就不需要调用这个关闭了 调用反而会消除session refresh
          // setDialog("login", false);
        } else {
          // 未认证，实名认证弹窗
          revertAccount();
          setType("sm-rz");
        }
      }
    });
  };

  // 注册
  const onRegister = async (data: any) => {
    // const path = window.sessionStorage.getItem("path");
    const pramas = {
      user: {
        mobile: data.mobile,
        phone_verify_code: data.phone_verify_code,
        name: data.name,
        password: data.password,
      },
      // _rucaptcha: data._rucaptcha,
    };
    await register(pramas, async response => {
      if (response.statusCode === 200) {
        message.success("注册成功");
        trackSignupEvent("Password");
        // 未认证，实名认证弹窗
        revertAccount();
        setType("sm-rz");
        return;
      } else {
        message.error(response.body.errors);
        return;
      }
    });
  };

  // 实名认证
  const onAuth = async (data: any) => {
    const params = {
      mobile_verify: {
        mobile: data.mobile,
        phone_verify_code: data.phone_verify_code,
        profession: data.profession,
        working_years: data.working_years,
      },
      // _rucaptcha: data._rucaptcha,
    };
    await mobileVerified(params, response => {
      if (response.statusCode === 200) {
        // const isNotVip = response.body.user.vip === OTHER_USER;
        // const isAlphaUser = response.body.user.isAlpha;
        message.success("实名认证成功");
        window.sessionStorage.setItem("refresh", "page");
        window.location.reload();
        // 关闭弹窗
        // !刷新就不需要调用这个关闭了 调用反而会消除session refresh
        // setDialog("login", false);
      } else if (response.statusCode === 401) {
        message.error(response.body || "实名认证失败");
      } else {
        message.error(response.body.errors);
      }
    });
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", function(event) {
  //     // 执行一些清理操作
  //     window.sessionStorage.removeItem("refresh");
  //     // 如果需要，可以设置一个确认对话框
  //     // 这会提示用户确认是否真的要离开页面
  //     event.preventDefault(); // 标准浏览器
  //     event.returnValue = ""; // IE浏览器
  //   });
  // }, []);

  useEffect(() => {
    if (dialog["login"]) {
      setType(_type);
    }
  }, [dialog["login"]]);

  useEffect(() => {
    if (dialog["login"]) {
      initCode(type);
    }
  }, [dialog["login"], type]);

  useEffect(() => {
    if (type !== "code") {
      // 只用初始化一次验证码即可
      (window as any).initAliyunCaptcha({
        SceneId: "nepl2ukj", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
        prefix: "mk2426", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
        mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: "#captcha-element", // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: "#captcha-button", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 320,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      });
      return () => {
        // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
        document.getElementById("aliyunCaptcha-mask")?.remove();
        document.getElementById("aliyunCaptcha-window-popup")?.remove();
      };
    }
  }, [type, isLogin]);

  const onCancel = () => {
    setDialog("login", false);
    if (type === "sm-rz") {
      forceLogout();
    }
  };

  const codeForm = (
    <div className="code-form">
      <p className="title">
        微信扫码<span>一键登录</span>
      </p>
      <p className="p-1">未注册的微信号将自动创建成机器之心新账号</p>
      <div id="code-node" />
      <p className="p-2">
        扫码登录即代表我已阅读并同意{" "}
        <a
          href="https://www.jiqizhixin.com/short_urls/f7dd70ed-41d4-47c0-ad8f-5871ed7a03c8"
          target="_blank"
        >
          《用户协议》
        </a>
      </p>
    </div>
  );
  const accountForm = (
    <div className="account-form">
      {isLogin ? (
        <Form form={form} onFinish={values => onLogin(values)}>
          <h2>欢迎回来</h2>
          {(accountSpan || account) && (
            <p className="account-form__label">手机号/邮箱</p>
          )}
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的手机号或邮箱" },
              {
                pattern: /^([^@]+@([^@\.]+\.)+[^@\.]+)|(\d{11})$/,
                message: "账号名不正确",
              },
            ]}
            name="login_name"
            className={`${(accountSpan || account) && "has-label"}`}
          >
            <Input
              placeholder={accountSpan || account ? "" : "手机号/邮箱"}
              onFocus={() => setAccountSpan(true)}
              onBlur={() => setAccountSpan(false)}
              onChange={e => {
                setAccount(e.target.value);
              }}
            />
          </Form.Item>
          {(passwordSpan || password) && (
            <p className="account-form__label">密码</p>
          )}
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的密码" },
              { min: 6, message: "密码最少是 6 位" },
            ]}
            name="password"
            className={`${(passwordSpan || password) && "has-label"}`}
          >
            <Input.Password
              placeholder={passwordSpan || password ? "" : "密码"}
              onFocus={() => setPasswordSpan(true)}
              onBlur={() => setPasswordSpan(false)}
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item className="account-form__button">
            <Button type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
          <div className="account-form__checkbox">
            <Form.Item
            // name="remember_me"
            // initialValue="false"
            // valuePropName="checked"
            >
              <Checkbox>记住密码</Checkbox>
            </Form.Item>
            <span>
              <a
                className="account-form__forget-password"
                onClick={() => {
                  window.sessionStorage.setItem("login", "member");
                  window.sessionStorage.setItem("pathname", url);
                  // window.location.replace("/forget-password");
                  history.push("/forget-password");
                }}
              >
                <img src={forgetIcon} alt="forget" />
                忘记密码
              </a>
              <a
                className="account-form__register"
                onClick={() => {
                  revertAccount();
                  setIsLogin(false);
                }}
              >
                创建用户
              </a>
            </span>
          </div>
        </Form>
      ) : (
        <Form form={form} onFinish={values => onRegister(values)}>
          <h2>欢迎加入</h2>
          {(accountSpan || account) && (
            <p className="account-form__label">手机号码</p>
          )}
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的手机号码" },
              {
                pattern: /^1[3456789]\d{9}$/,
                message: "请输入正确的手机号码",
              },
            ]}
            name="mobile"
            className={`${(accountSpan || account) && "has-label"}`}
          >
            <Input
              placeholder={accountSpan || account ? "" : "手机号码"}
              autoComplete="off"
              onFocus={() => setAccountSpan(true)}
              onBlur={() => setAccountSpan(false)}
              onChange={e => {
                setAccount(e.target.value);
              }}
            />
          </Form.Item>
          <div className="account-form__code-wrapper">
            {(codeSpan || code) && (
              <p className="account-form__label">验证码</p>
            )}
            <div className="account-form__code">
              <Form.Item
                hasFeedback
                rules={[{ required: true, message: "请输入正确的短信验证码" }]}
                name="phone_verify_code"
                className={`${(codeSpan || code) && "has-label"}`}
              >
                <Input
                  placeholder={codeSpan || code ? "" : "验证码"}
                  autoComplete="off"
                  onFocus={() => setCodeSpan(true)}
                  onBlur={() => setCodeSpan(false)}
                  onChange={e => {
                    setCode(e.target.value);
                  }}
                />
              </Form.Item>
              <div
                className={`send-code ${time > 0 && "send-code--time"}`}
                onClick={() => {
                  if (time <= 0) {
                    sendDecorator();
                  }
                }}
              >
                {time > 0 ? `${time}s 后重新发送` : "获取短信验证码"}
              </div>
              <div id="captcha-button" />
              <div id="captcha-element" />
            </div>
          </div>
          {(nameSpan || name) && <p className="account-form__label">昵称</p>}
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入昵称" }]}
            name="name"
            className={`${(nameSpan || name) && "has-label"}`}
          >
            <Input
              placeholder={nameSpan || name ? "" : "昵称"}
              autoComplete="off"
              onFocus={() => setNameSpan(true)}
              onBlur={() => setNameSpan(false)}
              onChange={e => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
          {(passwordSpan || password) && (
            <p className="account-form__label">密码</p>
          )}
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的密码" },
              { min: 6, message: "密码最少是 6 位" },
            ]}
            name="password"
            className={`${(passwordSpan || password) && "has-label"}`}
          >
            <Input.Password
              placeholder={passwordSpan || password ? "" : "密码"}
              autoComplete="off"
              onFocus={() => setPasswordSpan(true)}
              onBlur={() => setPasswordSpan(false)}
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item className="account-form__button">
            <Button type="primary" htmlType="submit">
              注册
            </Button>
          </Form.Item>
          <div className="account-form__login">
            已有账号，立刻
            <a
              onClick={() => {
                revertAccount();
                setIsLogin(true);
              }}
            >
              {" "}
              登录
            </a>
          </div>
        </Form>
      )}
      <div className={`account-form__agreement ${isLogin && "big-margin"}`}>
        注册即代表我已阅读并同意{" "}
        <a
          // todo: 用户协议链接，使用官网的吗？
          href="https://www.jiqizhixin.com/short_urls/f7dd70ed-41d4-47c0-ad8f-5871ed7a03c8"
          target="_blank"
        >
          《用户协议》
        </a>
      </div>
    </div>
  );
  const smRzForm = (
    <div className="sm-rz-form">
      <Form form={form} onFinish={values => onAuth(values)}>
        <h2>用户认证</h2>
        {(accountSpan || account) && (
          <p className="account-form__label">手机号码</p>
        )}
        <Form.Item
          hasFeedback
          rules={[
            { required: true, message: "请输入正确的手机号码" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的手机号码",
            },
          ]}
          name="mobile"
          className={`${(accountSpan || account) && "has-label"}`}
        >
          <Input
            placeholder={accountSpan || account ? "" : "手机号码"}
            onFocus={() => setAccountSpan(true)}
            onBlur={() => setAccountSpan(false)}
            onChange={e => {
              setAccount(e.target.value);
            }}
          />
        </Form.Item>
        <div className="account-form__code-wrapper">
          {(codeSpan || code) && <p className="account-form__label">验证码</p>}
          <div className="account-form__code">
            <Form.Item
              hasFeedback
              rules={[{ required: true, message: "请输入正确的短信验证码" }]}
              name="phone_verify_code"
              className={`${(codeSpan || code) && "has-label"}`}
            >
              <Input
                placeholder={codeSpan || code ? "" : "验证码"}
                autoComplete="off"
                onFocus={() => setCodeSpan(true)}
                onBlur={() => setCodeSpan(false)}
                onChange={e => {
                  setCode(e.target.value);
                }}
              />
            </Form.Item>
            <div
              className={`send-code ${time > 0 && "send-code--time"}`}
              onClick={() => {
                if (time <= 0) {
                  sendDecorator();
                }
              }}
            >
              {time > 0 ? `${time}s 后重新发送` : "发送验证码"}
            </div>
            <div id="captcha-button" />
            <div id="captcha-element" />
          </div>
        </div>
        <p className="sm-rz-form__tips">
          <img src={tipsIcon} alt="tips" />
          根据国家法律规要求,绑定手机号获得更高级的安全保护
        </p>
        {(professionSpan || profession) && (
          <p className="account-form__label">职业</p>
        )}
        <Form.Item
          hasFeedback
          name="profession"
          className={`${(professionSpan || profession) && "has-label"}`}
        >
          <Input
            placeholder={professionSpan || profession ? "" : "职业"}
            autoComplete="off"
            onFocus={() => setProfessionSpan(true)}
            onBlur={() => setProfessionSpan(false)}
            onChange={e => {
              setProfession(e.target.value);
            }}
          />
        </Form.Item>
        {(workingYearsSpan || workingYears) && (
          <p className="account-form__label">从业年限</p>
        )}
        <Form.Item
          hasFeedback
          name="working_years"
          className={`${(workingYearsSpan || workingYears) && "has-label"}`}
        >
          <Select
            placeholder={workingYearsSpan || workingYears ? "" : "从业年限"}
            options={[
              {
                label: "1 年内",
                value: "1年内",
              },
              {
                label: "1-3 年",
                value: "1-3年",
              },
              {
                label: "3-5 年",
                value: "3-5年",
              },
              {
                label: "5 年以上",
                value: "5年以上",
              },
            ]}
            onFocus={() => setWorkingYearsSpan(true)}
            onBlur={() => setWorkingYearsSpan(false)}
            onChange={(value: string) => {
              setWorkingYears(value);
            }}
          />
        </Form.Item>
        <Form.Item className="sm-rz-form__button">
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const forms = {
    code: codeForm,
    account: accountForm,
    "sm-rz": smRzForm,
  };

  // const checkBind = (cb = () => {}) => {
  //   // 账号登录（已实名）或实名认证后 判定是否拉起绑定微信。
  //   if (!currentUser.wechat_bind) {
  //     setDialog("wx-bind", true);
  //   } else {
  //     cb();
  //   }
  // };

  return (
    <Dialog
      visible={dialog["login"]}
      style={{ padding: 0, border: 0 }}
      onCancel={onCancel}
      layerClose={false}
      closeIcon={closeImg}
      closeStyle={{
        top: "20px",
        right: "26px",
        width: "18px",
      }}
    >
      <div className="login-dialog__desktop" style={styles[type]}>
        <div className="login-dialog__form">
          <div className="form__header">
            <img className="form__header-logo" src={logoImg} alt="logo" />
            {type !== "sm-rz" && (
              <div
                className="switch-method"
                onClick={() => {
                  setType(type === "code" ? "account" : "code");
                  setIsLogin(true);
                }}
              >
                <img
                  src={type === "code" ? accountImg : codeImg}
                  alt="switch"
                />
              </div>
            )}
            {type !== "sm-rz" && (
              <div
                className="switch-method__tips"
                onClick={() => {
                  setType(type === "code" ? "account" : "code");
                  setIsLogin(true);
                }}
              >
                {type === "code" ? "账号密码登录" : "微信扫码登录"}
              </div>
            )}
          </div>
          <div className="form__body">{forms[type]}</div>
        </div>
        <div className="login-dialog__cover" />
      </div>
    </Dialog>
  );
};

export default observer(loginDialog);
