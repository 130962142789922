import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import "./style.scss";
import globalStore from "../../../stores/globalStore";
import authStore from "../../../stores/authStore";
import codeImg from "../../../styles/images/member-inbox--pc/code@2x.png";
import { Tooltip } from "antd";

const MemberSidebar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    sidebarList,
    setSidebarList,
    // setSidebarHover,
    // resetSidebarHover,
  } = useContext(globalStore);

  const { memberInfo } = useContext(authStore);

  useEffect(() => {
    setSidebarList(currentPath);
  }, []);

  return (
    <div className="member-sidebar">
      <div className="sidebar-list">
        {sidebarList.map((item: any, index: number) =>
          index === sidebarList.length - 1 ? (
            memberInfo && memberInfo.listVip ? (
              <div
                style={item.style}
                key={index}
                className={`member-sidebar__item ${item.active &&
                  "member-sidebar__item--active"}`}
                onClick={() => {
                  if (memberInfo) {
                    history.push(item.path);
                  }
                }}
                // onMouseEnter={() => {
                //   setSidebarHover(item.text);
                // }}
                // onMouseLeave={() => {
                //   resetSidebarHover();
                // }}
              >
                <div>
                  <img
                    src={
                      item.hover || item.active ? item.activeIcon : item.icon
                    }
                    alt="icon"
                  />
                  {item.text}
                </div>
              </div>
            ) : (
              <Tooltip key={index} placement="right" title="通讯会员专享功能">
                <div
                  style={{ cursor: "not-allowed" }}
                  className={`member-sidebar__item`}
                  // onMouseEnter={() => {
                  //   setSidebarHover(item.text);
                  // }}
                  // onMouseLeave={() => {
                  //   resetSidebarHover();
                  // }}
                >
                  <div>
                    <img
                      src={
                        item.hover || item.active ? item.activeIcon : item.icon
                      }
                      alt="icon"
                    />
                    {item.text}
                  </div>
                </div>
              </Tooltip>
            )
          ) : (
            <div
              key={index}
              style={item.style}
              className={`member-sidebar__item ${item.active &&
                "member-sidebar__item--active"}`}
              onClick={() => {
                if (memberInfo) {
                  history.push(item.path);
                }
              }}
              // onMouseEnter={() => {
              //   setSidebarHover(item.text);
              // }}
              // onMouseLeave={() => {
              //   resetSidebarHover();
              // }}
            >
              <div>
                <img
                  src={item.hover || item.active ? item.activeIcon : item.icon}
                  alt="icon"
                />
                {item.text}
              </div>
              {index === 1 &&
                item.text === "专题解读" &&
                window.referenceCount && (
                  <span className="count">{window.referenceCount}</span>
                )}
            </div>
          )
        )}
      </div>
      <div className="sidebar-info">
        <img src={codeImg} alt="code" />
        <p>扫码关注</p>
        <p>每周获得最新推送</p>
      </div>
    </div>
  );
};

export default observer(MemberSidebar);
