import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";

import "./dialog.scss";
import { Dialog } from "../shared";
import successIcon from "../../styles/images/inbox-pay-success.png";
import proCodeImg from "../../styles/images/pro-code.png";
import correspondenceStore from "../../stores/memberInboxStore";

const ConvertDialog = () => {
  const { dialog, setDialog } = useContext(correspondenceStore);
  const onCancel = () => setDialog("convert-success", false);

  useEffect(() => {
    // api();
  }, []);

  return (
    <Dialog
      visible={dialog["convert-success"]}
      style={{ padding: 0 }}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "24px", right: "14px" }}
    >
      <div className="pay-success-dialog">
        <img src={successIcon} alt="successIcon" />
        <p>兑换成功</p>
        <div className="pay-success-dialog__qr">
          <img src={proCodeImg} alt="code" />
        </div>
        <div className="pay-success-dialog__bottom">
          <p>关注「机器之心PRO会员」服务号，</p>
          <p>访问左下角「收件箱」，每周获取最新会员内容</p>
        </div>
      </div>
    </Dialog>
  );
};

export default observer(ConvertDialog);
