import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { Input } from "antd";

import "./member-inbox-search.scss";
import { isWeiXin, urlTranslate } from "../../utils/wx_tools";
import { useHistory } from "react-router-dom";
import AuthStore from "../../stores/authStore";
import memberInboxStore from "../../stores/memberInboxStore";
import MemberInboxCard from "./MemberInboxCard";
import Loading from "../shared/loading/Loading";
import searchImg from "../../styles/images/pro-book-search.png";
import emptyImg from "../../styles/images/pro-search-empty.png";
import { isMobile } from "../../utils/tools";

const MemberInboxSearch: React.FC = () => {
  const history = useHistory();
  const { memberInfo, memberLoading, fetchMemberInfo } = useContext(AuthStore);
  const {
    revertSearch,
    fetchSearchList,
    searchList,
    searchLoading,
    searchPagination,
    wxLogin,
    keyword,
    setKeyword,
  } = useContext(memberInboxStore);
  const [style, setStyle] = useState({});
  const [isSearch, setIsSearch] = useState(false); //是否搜索

  const authFn = () => {
    if (
      (!memberLoading && !memberInfo) ||
      (memberInfo && !memberInfo.listVip)
    ) {
      history.replace("/m/inbox");
    }
  };

  const onScrollEvent = () => {
    if (!memberInfo) {
      setStyle({
        overflowY: "hidden",
      });
      (document.querySelector(
        ".member-inbox-search__list"
      ) as any).scrollTop = 0;
      wxLogin();
    } else if (!searchLoading && searchPagination.hasNextPage) {
      const dom = document.getElementsByClassName(
        "member-inbox-search__list"
      )[0];
      if (
        dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
        searchList.length < searchPagination.total
      ) {
        fetchSearchList(keyword, searchPagination.current + 1, 10);
      }
    }
  };

  useEffect(() => {
    if (isWeiXin()) {
      //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
      setTimeout(() => {
        fetchMemberInfo();
      }, 500);
    } else {
      if (isMobile()) {
        history.replace("/inbox-pc");
      } else {
        urlTranslate(history);
      }
    }
  }, []);

  useEffect(() => {
    if (!memberLoading) {
      authFn();
    }
  }, [memberLoading]);

  return (
    <div className="member-inbox-search">
      <Helmet>
        <title>会员收件箱 ｜ 机器之心PRO</title>
      </Helmet>
      <div className="member-inbox-search-content">
        <div className="member-inbox-search__header">
          <Input
            placeholder="输入关键词"
            type="text"
            value={keyword}
            bordered={false}
            allowClear
            onChange={event => {
              setKeyword(event.target.value);
              if (!event.target.value) {
                // 点击清除按钮，清空列表及搜索状态
                setIsSearch(false);
                revertSearch();
              }
            }}
            onPressEnter={event => {
              setKeyword(event.target.value);
              setIsSearch(!!event.target.value);
              fetchSearchList(event.target.value);
            }}
            prefix={
              <img
                src={searchImg}
                alt="search"
                style={{ width: "16px", height: "16px" }}
              />
            }
          />
          <div
            className="header__btn"
            onClick={() => {
              setIsSearch(!!keyword);
              keyword && fetchSearchList(keyword);
            }}
          >
            搜索
          </div>
        </div>
        <div
          className="member-inbox-search__list"
          style={style}
          onScrollCapture={onScrollEvent}
        >
          {searchList &&
            (searchList.length > 0
              ? searchList.map((item: any, index: number) => (
                  <MemberInboxCard key={index} model={item.source} hasTags />
                ))
              : isSearch &&
                !searchLoading && (
                  <div className="member-inbox-search__empty">
                    <div className="empty-img">
                      <img src={emptyImg} alt="empty" />
                    </div>
                    <p>暂未检索到相关内容</p>
                  </div>
                ))}
          {searchLoading && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default observer(MemberInboxSearch);
