import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import "./reference-report.scss";
import { MemberLayout, MemberNav, MemberSidebar } from "../shared/index";
import AuthStore from "../../stores/authStore";
import CorrespondenceStore from "../../stores/correspondenceStore";
// 后期再看需要挪过来吗?
import MyList from "../correspondence/MyList";
import ReferenceReportInfo from "./ReferenceReportInfo";
import LoginDialog from "../memberInbox/LoginDialog";
// import { unlockMemberApi } from "../../api/index";
// import { message } from "antd";

const ReferenceReport: React.FC = () => {
  // const history = useHistory();
  const { id } = useParams();
  const {
    memberLoading,
    memberInfo,
    fetchMemberInfo,
    currentUser,
  } = useContext(AuthStore);
  const {
    fetchDetail,
    detail,
    detailLoading,
    setDialog,
    setIsBack,
    revertDetail,
  } = useContext(CorrespondenceStore);

  // const [buy, setBuy] = useState(false);
  // const [disable, setDisable] = useState(false);

  // const authFn = () => {
  //   if (!memberLoading && !memberInfo) {
  //     window.sessionStorage.setItem("id", id);
  //     setDialog("login", true);
  //   }
  //   // 已登录未实名认证
  //   // currentUser && !currentUser.mobile_verified && history.replace("/auth");
  // };

  const onScrollEvent = () => {
    if (!memberLoading && !memberInfo) {
      (document.querySelector(
        ".reference-report__content"
      ) as any).scrollTop = 0;
      setDialog("login", true);
    } else {
      if (!detailLoading) {
        const dom = document.getElementsByClassName(
          "reference-report__content"
        )[0];
        if (dom && dom.scrollTop > dom.clientHeight * 0.5) {
          setIsBack(true);
        } else {
          setIsBack(false);
        }
      }
    }
  };

  useEffect(() => {
    revertDetail();
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    setTimeout(() => {
      if (!(currentUser && !currentUser.mobile_verified)) {
        fetchDetail(id);
        window.sessionStorage.removeItem("login");
        window.sessionStorage.removeItem("pathname");
        setIsBack(false);
      }
    }, 500);
  }, []);

  // useEffect(() => {
  //   if (!memberLoading) {
  //     authFn();
  //   }
  // }, [memberLoading]);

  // useEffect(() => {
  //   if (memberInfo && detail) {
  //     if (memberInfo.points >= detail.unlockSpend) {
  //       setBuy(true);
  //     }
  //   }
  // }, [memberInfo, detail]);

  // const lockFn = async () => {
  //   if (disable) {
  //     return false;
  //   }
  //   setDisable(true);
  //   await unlockMemberApi({
  //     id,
  //   });
  //   await fetchMemberInfo();
  //   fetchDetail(id);
  //   message.open({
  //     type: "success",
  //     duration: 3,
  //     className: "ant-toast",
  //     content: "已解锁",
  //   });
  //   setDisable(false);
  // };

  return (
    <div className="reference-report">
      <Helmet>
        <title>{detailLoading ? "" : detail.title} ｜ 机器之心PRO</title>
      </Helmet>
      <LoginDialog />
      <MemberLayout>
        <MemberNav
          refreshFn={() => {
            fetchMemberInfo();
            fetchDetail(id);
          }}
        />
        <MemberSidebar />
        <div className="reference-report__content-wrapper">
          {/* report-detail__content类名MyList组件专用 */}
          <div
            className="reference-report__content report-detail__content"
            onScrollCapture={onScrollEvent}
          >
            <MyList type="refer" conName="report" />
            <ReferenceReportInfo />
          </div>
        </div>
      </MemberLayout>
    </div>
  );
};

export default observer(ReferenceReport);
