import moment from "moment";

// 是否当前年判断
const isCurrentYear = (time: any) => {
  const currentYear: number = new Date().getFullYear();
  const isNow = time.includes(currentYear);
  return isNow;
};

const formatDetail = (initData: any) => {
  return {
    ...initData,
    title: initData.title,
    summary: initData.summary,
    date: initData.publishedAt
      ? moment(initData.publishedAt).format("YYYY.MM.DD")
      : "",
    reportCatalog:
      initData.list && Array.isArray(initData.list)
        ? {
            activeIndex: 0,
            images: initData.list.map((url: string) => ({
              preview: url,
              thumbnail: url,
            })),
          }
        : null,
  };
};

const formatList = (initData: any) =>
  initData.map((item: any) => ({
    ...item,
    publishedTime: item.publishedAt
      ? isCurrentYear(item.publishedAt)
        ? moment(item.publishedAt).format("MM.DD")
        : moment(item.publishedAt).format("YY.MM.DD")
      : "",
  }));

const formatSearchList = (initData: any) =>
  initData.map((item: any) => {
    const overData = item.highlight ? item.highlight : {};
    const data = {
      ...item.source,
      ...overData,
    };
    return {
      ...data,
      source: {
        ...item.source,
        title: data.title || "",
        // summary: data.summary || "",
        // hotTags: data.hotTags || [],
        // hotTags: new Array(20).fill("测试tag"),
        publishedTime: data.publishedAt
          ? moment(data.publishedAt * 1000).format("YY.MM.DD")
          : "",
        unlocked: true, //es没法根据不同用户呈现不同解锁状态，因此统不要锁
      },
    };
  });

const formatMemberInfo = initData => {
  return {
    ...initData,
    expiredAt0: initData.expiredAt
      ? moment(initData.expiredAt).format("YYYY.MM.DD")
      : "",
    expiredAt1: initData.expiredAt
      ? moment(initData.expiredAt).format("YYYY-MM-DD")
      : "",
    baseExpiredAt: initData.baseExpiredAt
      ? moment(initData.baseExpiredAt).format("YYYY.MM.DD")
      : "",
    communityVip: initData.community,
    listVip: initData.communication,
    proVip: initData.paying,
    bio:
      !!initData.company && !!initData.position
        ? initData.company + "·" + initData.position
        : (!!initData.company ? initData.company : "") +
          (!!initData.position ? initData.position : ""),
  };
};

export { formatDetail, formatList, formatSearchList, formatMemberInfo };
