import { getQuery } from "../../../utils/tools";

export default function(fn1?: any, fn2?: any, fn3?: any, fn4?: any, fn5?: any) {
  let purchase_source = "";
  const emFn = () => {};
  const _fn1 = fn1 || emFn;
  const _fn2 = fn2 || emFn;
  const _fn3 = fn3 || emFn;
  const _fn4 = fn4 || emFn;
  const _fn5 = fn5 || emFn;
  /**
   * 这里其实就是根据url参数来判断是否直接弹某个窗
   * 1.member=show
   * 2.integral=show
   * 3.new=show
   * 4.year=show
   * 5.cost=show
   */
  const res1 = getQuery("member");
  const res2 = getQuery("integral");
  const res3 = getQuery("year");
  const res4 = getQuery("cost");
  if (location.pathname === "/inbox") {
    if (res1 === "show") {
      // 情况1: 新用户专享
      purchase_source = "direct";
      // 执行回调
      _fn1();
    } else if (res2 === "show") {
      // 情况2: 购买积分
      purchase_source = "direct";
      // 执行回调
      _fn2();
    } else if (res3 === "show") {
      // 情况3: 年度订阅全价弹窗
      purchase_source = "direct";
      // 执行回调
      _fn3();
    } else if (res4 === "show") {
      // 情况4: 续费弹窗
      purchase_source = "direct";
      // 执行回调
      _fn4();
    } else {
      // 情况5: 来自收件箱首页
      purchase_source = "inbox";
      // 执行回调
      _fn5();
    }
  } else {
    // 情况4: 来自收件箱首页详情页
    purchase_source = "article";
    // 执行回调
    _fn4();
  }
  return purchase_source;
}
