import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./dialog.scss";
import { Dialog } from "../../shared";
import correspondenceStore from "../../../stores/correspondenceStore";
import AuthStore from "../../../stores/authStore";
import { unlockMemberApi, exchangeCode } from "../../../api/index";
// import { buyInboxMemberEvent } from "../../../utils/ga_events";
import bgImg from "../../../styles/images/member-inbox--pc/convert-bg.png";
import tipsIcon from "../../../styles/images/inbox-tips.png";
import { Input, message } from "antd";

const ToConvertDialog: React.FC = () => {
  const { id } = useParams();
  const { fetchMemberInfo } = useContext(AuthStore);
  const { dialog, setDialog } = useContext(correspondenceStore);

  const [value, setValue] = useState("");

  const onCancel = () => {
    setDialog("to-convert", false);
  };

  const successFn = async () => {
    if (id) {
      await unlockMemberApi({
        id,
      });
    }
    setValue("");
    fetchMemberInfo();
    onCancel();
    setDialog("convert-success", true);
  };

  useEffect(() => {
    if (dialog["to-convert"]) {
      //
    }
  }, [dialog["to-convert"]]);

  return (
    <Dialog
      style={{ padding: "0", borderRadius: "10px" }}
      visible={dialog["to-convert"]}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ top: "14px", right: "14px", width: "12px" }}
    >
      <div className="to-convert-dialog-1">
        <div className="to-convert__top">
          <img src={bgImg} alt="bg" />
        </div>
        <div className="to-convert__info">
          <p>兑换通讯会员</p>
          <div className="handle">
            <Input
              value={value}
              type="text"
              placeholder="如有优惠码，请在这里输入"
              onChange={(e: React.BaseSyntheticEvent) =>
                setValue(e.target.value)
              }
            />
            <div
              className="c-btn"
              onClick={async () => {
                if (value) {
                  const result = await exchangeCode(value);
                  if (result.status === "success") {
                    successFn();
                  } else {
                    message.error(result.message);
                  }
                }
              }}
            >
              立即兑换
            </div>
          </div>
        </div>
        <div className="to-convert__bottom">
          <div className="bottom__title">
            <img src={tipsIcon} alt="tips" />
            <p>兑换规则</p>
          </div>
          <div className="bottom__regular">
            <p>
              兑换账号默认为当前登录账号，兑换前请核实账户信息，确保登录账户为常用账号，兑换成功后权益不可转移
            </p>
            <p>
              会员有效期从兑换日算起，统一账户使用多个兑换码，会员有效期自动延长
            </p>
            <p>
              请在有效期内兑换，逾期兑换码将失效，若兑换码无法兑换，请联系客服核实
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default observer(ToConvertDialog);
