import { message } from "antd";
import { logout } from "../api/index";

export const isMobile = () => {
  return /Mobi|Android|iPhone/i.test(navigator.userAgent);
};

export const rem = (pixels: any) => {
  const vw_design = 375;
  return isMobile() ? `${(pixels / vw_design) * 10}rem` : `${pixels}px`;
};

export const getQuery = (variable): any => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return "";
};

export const getWh = () => {
  return {
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  };
};

export const forceLogout = async (back?: boolean) => {
  await logout(response => {
    if (response.statusCode === 200) {
      window.currentUser = null;
      back ? (window.location.href = "/") : window.location.reload();
      window.sessionStorage.removeItem("path");
    } else {
      message.error(response.body.errors);
    }
  });
};

/**
 * root是要监听的根元素，我们要对root节点内的img元素进行监听
 * 这里用到Promise.all
 */
export const onAllImgLoaded = async root => {
  const imgNodes =
    root instanceof HTMLImageElement ? [root] : root.querySelectorAll("img");
  //为了使用Array的map方法
  let imgArr = Array.prototype.slice.call(imgNodes);
  return await Promise.all(
    imgArr.map(img => {
      new Promise(resolve => {
        img.addEventListener("load", () => resolve(img));
      });
    })
  );
};

export const replaceAll = (
  str: string,
  o: string,
  n: string,
  model?: "string"
) => {
  const reg = new RegExp(`${o}`, model || "g");
  return str.replace(reg, n);
};

/**
 * 删除url指定的查询参数
 * @param url  string
 * @param paramsToRemove array
 * @returns
 */
export const removeQueryParams = (url: string, paramsToRemove: any) => {
  // 解析URL
  const urlObj = new URL(url);

  // 遍历要删除的参数
  paramsToRemove.forEach((param: string) => {
    // 检查URL的searchParams中是否有该参数
    if (urlObj.searchParams.has(param)) {
      // 删除参数
      urlObj.searchParams.delete(param);
    }
  });

  // 构建没有指定参数的URL
  const newUrl = urlObj.href.split("?")[0]; // 获取没有查询参数的基础URL
  const params = urlObj.searchParams.toString(); // 获取剩余的查询参数字符串
  if (params) {
    return `${newUrl}?${params}`; // 如果还有剩余参数，拼接它们
  }
  return newUrl; // 如果没有剩余参数，返回基础URL
};

export const extractUUID = (input: string) => {
  const regex = /(\/.+)(\/.+)/;
  const match = input.match(regex);
  return match ? (match[2] ? match[2] : "") : "";
};

/**
 * map 对象匹配器
 * @param maps
 * @param key
 * @returns
 */
export const mapMatch = (maps: any, key: string) => {
  // 循环 maps 中的每个正则表达式
  for (const [regex, route] of maps) {
    if (regex.test(key)) {
      return route;
    }
  }
  return null;
};

export const scanCb = (fn1 = () => {}, fn2 = () => {}, fn3 = () => {}) => {
  const _window = window as any;
  const res = getQuery("error");
  if (res === "auth_is_taken") {
    fn2();
    if (_window.currentUser) {
      // 登录则显示
      fn1();
    }
    return true;
  }
  fn3();
  return false;
};

export const observerNodeStyle = cb => {
  // 定义回调函数，处理元素大小变化
  const resizeObserverCallback = entries => {
    for (let entry of entries) {
      cb(entry, entry.contentRect);
    }
  };

  // 创建 ResizeObserver 实例并传入回调函数
  const resizeObserver = new ResizeObserver(resizeObserverCallback);
  // resizeObserver.observe()
  // resizeObserver.disconnect()
  return resizeObserver;
};
