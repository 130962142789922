import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./dialog.scss";
import { Dialog } from "../../shared";
import agreeIcon from "../../../styles/images/inbox-agree.png";
import disagreeIcon from "../../../styles/images/inbox-disagree.png";
import tipsIcon from "../../../styles/images/inbox-tips.png";
import correspondenceStore from "../../../stores/correspondenceStore";
import {
  initWxPayCode,
  // debugFn,
  // isWxLogin,
  fetchWxPayStatus,
} from "../../../utils/wx_tools";
import AuthStore from "../../../stores/authStore";
import { fetchMemberInfoApi, unlockMemberApi } from "../../../api/index";
import { buyInboxMemberEvent } from "../../../utils/ga_events";
import checkOrigin from "../member-layout/checkOrigin";
import { onAllImgLoaded } from "../../../utils/tools";
// import { message } from "antd";
import QRCode from "qrcode";

interface Props {
  onSuccess?(): any;
}

// 非新用户购买弹窗
const CostDialog: React.FC<Props> = (props: Props) => {
  const { onSuccess = () => {} } = props;
  const { id } = useParams();
  const { fetchMemberInfo, memberInfo } = useContext(AuthStore);
  const {
    dialog,
    setDialog,
    xy,
    // xyText,
    // wxLogin,
    xfOyId,
    oyId,
    discount,
    setPurchase,
  } = useContext(correspondenceStore);
  const onCancel = () => {
    setDialog("cost", false);
  };
  const [info, setInfo] = useState<any>({});
  const [show, setShow] = useState<any>(false);
  const [agree, setAgree] = useState<any>(true);
  const [timer, setTimer] = useState<any>(null);
  const purchase_source = checkOrigin();

  const fetchInfo = () => {
    // 折扣discount/全价购买
    return Promise.all([
      fetchMemberInfoApi(discount ? xfOyId : oyId).then((res: any) => {
        setInfo(res);
      }),
    ]);
  };

  const updatePayStatus = (data: any) => {
    fetchWxPayStatus(data.orderId).then(async () => {
      if (id) {
        await unlockMemberApi({
          id,
        });
      }
      // 标记是刚购买过的会员
      setPurchase("cost");
      fetchMemberInfo();
      onSuccess();
      setDialog("pay-success", true);
      buyInboxMemberEvent({
        transaction_id: data.shortId,
        value: data.final_price / 100,
        item_id: data.proProductId,
        item_name: data.proProductName,
        price: data.final_price / 100,
        purchase_source,
        user_id: memberInfo.id,
        user: memberInfo.username,
      });
    });
  };

  useEffect(() => {
    setShow(false);
    if (dialog.cost) {
      fetchInfo().then(() => {
        onAllImgLoaded(document.querySelector(".cost-dialog-1")).then(() => {
          setShow(true);
        });
      });
    } else {
      clearInterval(timer);
    }
  }, [dialog.cost]);

  useEffect(() => {
    if (dialog.cost && info.price) {
      initWxPayCode(discount ? xfOyId : oyId).then(async (data: any) => {
        QRCode.toDataURL(data.codeUrl, { margin: 0 }).then(url => {
          const dom = document.querySelector(".qr-code");
          dom.setAttribute("src", url);
          clearInterval(timer);
          const t = setInterval(() => {
            updatePayStatus(data);
          }, 1000);
          setTimer(t);
        });
      });
    }
  }, [dialog.cost, info.price]);

  return (
    <Dialog
      style={{ padding: "0", borderRadius: "10px" }}
      visible={dialog.cost}
      onCancel={onCancel}
      layerClose={false}
      closeStyle={{ color: "rgba(55,65,81,0.2)", top: "20px", right: "12px" }}
      // needAuthCb={
      //   memberInfo && isWxLogin()
      //     ? null
      //     : () => {
      //         wxLogin();
      //       }
      // }
    >
      <div className={`cost-dialog-1 ${show && "cost-dialog-1--show"}`}>
        <div className="cost-dialog-1__header">
          <img src={info.show_image_url} alt="" />
        </div>
        {info.final_price && (
          <div
            className="cost-dialog-1__code"
            style={info.desc ? {} : { marginBottom: "20px" }}
          >
            <div className="code">
              <img className="qr-code" src="" alt="code" />
            </div>
            <div className="info">
              <p>{(info.final_price / 100).toFixed(1)}</p>
              <p>微信扫码支付，开通订阅</p>
              <div className="cost-tips">
                <img
                  src={agree ? agreeIcon : disagreeIcon}
                  alt="agreeIcon"
                  onClick={() => setAgree(!agree)}
                />
                <div>
                  我已阅读并同意{" "}
                  <a href={xy} target="_blank">
                    《用户服务协议》
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {info.desc && (
          <div className="cost-intro">
            <div className="cost-intro__title">
              <img src={tipsIcon} alt="agreeIcon" />
              权益详细说明
            </div>
            <div className="cost-intro__desc">
              {info.desc.split("\r\n").map((p: string, index: number) => (
                <p key={index}>{p}</p>
              ))}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default observer(CostDialog);
