import { createContext } from "react";
import { observable, action } from "mobx";
import { message } from "antd";
import { trackLoginEvent } from "../utils/ga_events";
import {
  fetchMemberArticleApi,
  fetchMemberListApi,
  fetchReferenceListApi,
  fetchSearchListApi,
  fetchNewCodeVerify,
  fetchNewCodeVerify2,
  PROTOCOL,
  WWWHost,
} from "../api/index";
import {
  formatDetail,
  formatList,
  formatSearchList,
} from "../utils/formatMemberInboxData";

class correspondenceStore {
  @observable public dialog = {
    params: {} as any,
    "new-buy": false,
    cost: false,
    "pay-error": false,
    "pay-success": false,
    integral: false,
    login: false,
    "invalid-code": false,
    "guide-signin": false,
    "exchange-error": false,
    "edit-info": false,
    "user-agreement": false,
    "wx-bind": false,
    "to-convert": false,
    "convert-success": false,
  };
  @observable public xyText = "请阅读并勾选协议，否则无法进行操作";
  @observable public xy =
    "https://www.jiqizhixin.com/short_urls/d2e4df6c-709f-436a-b22c-15b4d9c1ebf3";
  @observable public baseXy =
    "https://www.jiqizhixin.com/short_urls/d2e4df6c-709f-436a-b22c-15b4d9c1ebf3";
  @observable public detail: any = null;
  @observable public detailLoading = true;
  @observable public activeIndex = 0;
  @observable list: any = [];
  @observable loading = false;
  @observable public pagination = {
    total: 0,
    current: 1,
    pageSize: 20,
    hasNextPage: true,
    referenceTotal: 0,
  };
  @observable public params: any = {
    page: 1,
    per: 20,
  };
  @observable searchList: any = [];
  @observable public searchLoading = false;
  @observable public searchPagination = {
    total: 0,
    current: 1,
    pageSize: 20,
    hasNextPage: true,
  };
  @observable public keyword = "";
  @observable public time = 0;
  @observable public isBack = false;
  @observable public showList = false; //详情页默认收起列表
  // 是否折扣购买会员
  @observable public discount = true;
  // 续费包年 155.5
  @observable public xfOyId = "9e2fe18c-3814-4901-a14c-1cf5304eba11";
  // 原价包年 514.8
  @observable public oyId = "4b36cd61-60be-4328-b684-0314c4f99a7a";
  // 新用户三个月 38.9
  @observable public ntmId = "a69db391-c677-4417-8eb1-dc7c5e58dd64";
  // 新用户包年 155.5
  @observable public noyId = "7ee8fc24-bf6f-4d4f-9e8d-d3f6eafe0300";
  // 积分 50/2.99
  @observable public jfId = "dc2a39bd-a9db-4a2b-9ce2-0e56592eb808";
  // 标记是刚购买的新用户，不关闭页面不会再次提示，直到下次打开新页面
  @observable public purchase = "";

  @action
  setDialog = (name: string, value: boolean, params?: any) => {
    this.dialog.params = params || {};
    this.dialog[name] = value;
    if (name === "login" && value === false) {
      window.sessionStorage.removeItem("refresh");
    }
  };

  @action
  setIsBack = (value: boolean) => {
    this.isBack = value;
  };

  @action
  setDiscount = (value: boolean) => {
    this.discount = value;
  };

  @action
  setPurchase = (value: string) => {
    this.purchase = value;
  };

  @action
  setShowList = (value: boolean) => {
    this.showList = value;
  };

  @action
  fetchDetail = async (id: string) => {
    this.detailLoading = true;
    this.revertDetail();
    const res = await fetchMemberArticleApi(id);
    this.detail = formatDetail(res.content);
    this.detailLoading = false;
  };

  //去掉字符串两端的空格
  @action
  trim = str => {
    return str.replace(/(^\s*)|(\s*$)/g, "");
  };
  //去除换行
  @action
  ClearBr = key => {
    key = key.replace(/<\/?.+?>/g, "");
    key = key.replace(/[\r\n]/g, "");
    return key;
  };

  @action
  isInViewPort = (element: any) => {
    const viewWidth = window.innerWidth || document.documentElement.clientWidth;
    const viewHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const { top, right, bottom, left } = element.getBoundingClientRect();

    return top >= 0 && left >= 0 && right <= viewWidth && bottom <= viewHeight;
  };

  @action
  scrollChoose = (type: string) => {
    const container: any = document.querySelector(`.${type}-detail__content`);
    if (container) {
      let activeArr = [];
      const nearItem: any = {
        y: Infinity,
      };
      const items = Array.from(
        document.querySelectorAll(`.${type}-detail-body h3`)
      ).filter((item: any) => !!this.ClearBr(this.trim(item.innerText)));
      items.forEach((it: any, index: number) => {
        if (this.isInViewPort(it)) {
          activeArr.push(index);
        }
        const res = Math.abs(container.scrollTop - it.offsetTop);

        if (res < nearItem.y) {
          nearItem.y = res;
          nearItem.index = index;
        }
      });
      let activeIndex = 0;
      if (activeArr.length > 0) {
        activeIndex = activeArr[0];
      } else {
        activeIndex = nearItem.index;
      }
      this.activeIndex = activeIndex;
    }
  };

  @action
  revert = () => {
    this.list = [];
    this.params = {
      page: 1,
      per: 20,
    };
    this.pagination = {
      total: 0,
      current: 1,
      pageSize: 20,
      hasNextPage: true,
      referenceTotal: 0,
    };
  };

  @action
  revertDetail = () => {
    this.detail = null;
    this.detailLoading = true;
  };

  @action
  revertSearch = () => {
    this.searchList = [];
    this.searchPagination = {
      total: 0,
      current: 1,
      pageSize: 20,
      hasNextPage: true,
    };
    this.keyword = "";
  };

  @action
  nextPage = (key: string) => {
    this.params.page += 1;
    this.fetchList(key, true);
  };

  @action
  fetchList = async (key: string, next?: boolean) => {
    this.loading = true;
    // this.revertSearch();
    !next && this.revert();
    if (
      !(this.params.page > 1 && this.pagination.current === this.params.page)
    ) {
      const res =
        key === "comm"
          ? await fetchMemberListApi(this.params)
          : await fetchReferenceListApi(this.params);
      // this.list、this.pagination赋值顺序不能变，
      // 因为this.pagination.current如果先赋值会改变判断条件的结果
      this.list =
        this.params.page > 1 && this.pagination.current !== this.params.page
          ? [...this.list, ...formatList(res.contents)]
          : formatList(res.contents);
      this.pagination = {
        total: res.totalCount,
        current: res.currentPage,
        pageSize: this.params.per,
        hasNextPage: res.hasNextPage,
        referenceTotal: key === "comm" ? 0 : res.totalCount,
      };
    }
    this.loading = false;
  };

  // 搜索列表
  fetchSearchList = async (keyword?: any, page?: any, per?: any) => {
    this.searchLoading = true;
    const res = await fetchSearchListApi(keyword || "", page || 1, per || 20);
    this.searchPagination = {
      total: res.totalCount,
      current: res.currentPage,
      pageSize: per || 20,
      hasNextPage: res.hasNextPage,
    };
    this.searchList =
      page && page > 1
        ? [...this.searchList, ...formatSearchList(res.contents)]
        : formatSearchList(res.contents);
    this.searchLoading = false;
  };

  @action
  setKeyword = (keyword: any) => {
    this.keyword = keyword;
  };

  @action public setCountdown = () => {
    let timer = null;
    this.time = 60;
    timer && clearInterval(timer);
    timer = setInterval(() => {
      if (this.time === 0) {
        clearInterval(timer);
      }
      this.time -= 1;
    }, 1000);
  };

  @action public onSendCode2 = async (data: any) => {
    if (data.mobile) {
      await fetchNewCodeVerify2(data, response => {
        if (response.statusCode === 200) {
          this.setCountdown();
          message.success("已将验证码发送到您的手机，请查收！");
        } else {
          if (response.statusCode === 422) {
            const errorMsg = response.body.errors[0];
            message.error(errorMsg);
          } else {
            const errorMsg = response.body.errors.join(",");
            message.error(
              errorMsg === "手机号码已被注册。"
                ? "该手机号已存在，请使用新手机号绑定"
                : errorMsg
            );
          }
        }
      });
    } else {
      message.error("手机号不合法!");
    }
  };

  @action public onSendCode = async (data: any) => {
    return new Promise(async (resolve, reject) => {
      await fetchNewCodeVerify(data, response => {
        if (response.statusCode === 200) {
          this.setCountdown();
          message.success("已将验证码发送到您的手机，请查收！");
          resolve(response.body);
        } else {
          if (response.statusCode === 422) {
            const errorMsg = response.body.errors[0];
            message.error(errorMsg);
            reject(errorMsg);
          } else {
            const errorMsg = response.body.errors.join(",");
            const msg =
              errorMsg === "手机号码已被注册。"
                ? "该手机号已存在，请使用新手机号绑定"
                : errorMsg;
            message.error(msg);
            reject(msg);
          }
        }
      });
    });
  };

  @action public wxLogin = (path?: any, cb?: any) => {
    let url = "";
    const mdetect = require("mdetect");
    trackLoginEvent("Wechat");
    const pathname = path ? path : `${location.pathname}${location.search}`;
    const origin = `${location.origin}${pathname}`;
    if (mdetect.isWechat()) {
      window.localStorage.setItem("login", "wxLogin");
      url = `${window.location.origin}/auth/wechatservice?origin=${origin}`;
      cb && cb();
    } else {
      url = `https://open.weixin.qq.com/connect/qrconnect?appid=${window.appid}&scope=snsapi_login&redirect_uri=${PROTOCOL}//${WWWHost}/auth/wechat/callback&state=&login_type=jssdk&self_redirect=default&origin=${origin}`;
    }
    window.location.href = url;
  };

  @action
  updateActiveThumbnail = index => {
    this.detail.reportCatalog.activeIndex = index;
  };
}

export default createContext(new correspondenceStore());
