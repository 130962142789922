import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import "./my-list.scss";
import CorrespondenceStore from "../../stores/correspondenceStore";
import CorrespondenceCard from "./CorrespondenceCard";
import Loading from "../shared/loading/Loading";
import threeLines from "../../styles/images/three-lines-icon.svg";

interface Props {
  type: string; //通讯：comm, 参考：refer
  conName: string; //通讯：correspondence, 参考：reference
}
const MyList: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const { type, conName } = props;
  const {
    list,
    pagination,
    loading,
    fetchList,
    nextPage,
    showList,
    setShowList,
  } = useContext(CorrespondenceStore);
  const [conHeight, setConHeight] = useState<any>("0");
  const [activeId, setActiveId] = useState("");
  const container: any = document.querySelector(`.${conName}-detail__content`);

  const onScrollEvent = () => {
    if (!loading && pagination.hasNextPage) {
      const dom = document.getElementsByClassName(
        "correspondence-my-list__content"
      )[0];
      if (
        dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
        list.length < pagination.total
      ) {
        nextPage(type);
      }
    }
  };

  useEffect(() => {
    // revert();
    //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
    fetchList(type); //通讯
    window.sessionStorage.removeItem("login");
    window.sessionStorage.removeItem("pathname");
    setActiveId("");
  }, []);

  useEffect(() => {
    // 获取浏览器宽度，设置默认展开or收起
    // 大于1440，默认展开列表，否则默认收起
    setShowList(window.innerWidth >= 1440 ? true : false);
    id && setActiveId(id);
  }, [window.innerWidth, id]);

  useEffect(() => {
    container && setConHeight(`${container.clientHeight - 52.5}px`);
  }, [container]);

  return (
    <div className={`common-my-list ${showList && "common-my-list--show"}`}>
      <div
        className="common-my-list__btn"
        onClick={() => setShowList(!showList)}
      >
        <img src={threeLines} alt="icon" />
        {showList ? "收起列表" : "展开列表"}
      </div>
      <div
        className={`common-my-list__content ${showList &&
          "common-my-list__content--block"}`}
        id="message-content"
        style={{ maxHeight: conHeight }}
        onScrollCapture={onScrollEvent}
      >
        <div
          className="common-my-list__list"
          // onScrollCapture={onScrollEvent}
          onClick={() => {
            // 获取浏览器宽度，设置默认展开or收起
            // 大于1440，默认展开列表，否则默认收起
            setShowList(window.innerWidth >= 1440 ? true : false);
          }}
        >
          {list &&
            list.length > 0 &&
            list.map((item: any, index: number) => (
              <CorrespondenceCard
                key={index}
                model={item}
                type={type}
                activeId={activeId}
                onlyTitle
              />
            ))}
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
};

export default observer(MyList);
