import React, { useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import "./style.scss";
import authStore from "../../stores/authStore";
import memberInboxStore from "../../stores/memberInboxStore";
import lockIcon from "../../styles/images/lock-icon.png";
import pdfImg from "../../styles/images/inbox-pdf.svg";

interface Props {
  model: any;
  hasTags?: boolean;
}

const MemberInboxCard: React.FC<Props> = (props: Props) => {
  const { model = {}, hasTags = false } = props;
  const history = useHistory();
  const { memberInfo } = useContext(authStore);
  const { wxLogin } = useContext(memberInboxStore);

  useEffect(() => {
    // api
  }, []);

  return (
    <div
      className="member-inbox-card"
      onClick={() => {
        if (!memberInfo) {
          window.sessionStorage.setItem("id", model.id);
          wxLogin();
        } else {
          if (model.category && model.category === "report") {
            history.push(`/inbox-report/${model.id}`);
            // } else if (model.category && model.category === "interpretation") {
            //   history.push(`/inbox-interpretation/${model.id}`);
          } else {
            history.push(`/m/inbox/${model.id}`);
          }
        }
      }}
    >
      <div className="member-inbox-card__info">
        <div
          className={`member-inbox-card__title ${model.unlocked &&
            "member-inbox-card__title--open"}`}
        >
          <p dangerouslySetInnerHTML={{ __html: model.title }} />
          {!model.unlocked && <img src={lockIcon} alt="lock" />}
        </div>
        <p>{model.publishedTime}</p>
      </div>
      {model.summary && (
        // <p>
        //   {model.category && model.category === "report" && (
        //     <img src={pdfImg} alt="pdf" />
        //   )}
        //   <span dangerouslySetInnerHTML={{ __html: model.summary }} />
        // </p>
        <p>
          {model.category && model.category === "report" && (
            <img src={pdfImg} alt="pdf" />
          )}
          {model.summary}
        </p>
      )}
      {hasTags && model.hotTags && model.hotTags.length > 0 && (
        <div className="member-inbox-card__tags">
          {model.hotTags.map((item: any, index: number) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default observer(MemberInboxCard);
