import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { message } from "antd";

import "./style.scss";
import { isWeiXin, isWxLogin, urlTranslate } from "../../utils/wx_tools";
import { Icon } from "../shared";
import { getQuery, isMobile } from "../../utils/tools";
import { checkCode, specialCheckCode, exchangeCode } from "../../api/index";
import authStore from "../../stores/authStore";
import memberInboxStore from "../../stores/memberInboxStore";
import titleImg from "../../styles/images/member-inbox-title.png";
import jewelImg from "../../styles/images/jewel-icon.svg";
// import copperCash from "../../styles/images/copper-cash-icon.svg";
// import invoiceImg from "../../styles/images/member-inbox-invoice.png";
import MemberInboxCard from "./MemberInboxCard";
import AssistantCodeModal from "../shared/modal/AssistantCodeModal";
import Loading from "../shared/loading/Loading";
import PayErrorDialog from "./PayErrorDialog";
import PaySuccessDialog from "./PaySuccessDialog";
import BuyIntegralDialog from "./BuyIntegralDialog";
import CostDialog from "./CostDialog";
import checkOrigin from "./checkOrigin";
import InvalidCodeDialog from "./InvalidCodeDialog";
import GuideSigninDialog from "./GuideSigninDialog";
import ExchangeErrorDialog from "./ExchangeErrorDialog";
import NewBuyDialog from "./NewBuyDialog";
import EditInfoDialog from "./EditInfoDialog";
import buyType from "./buyType";
import aboutTo from "../../styles/images/about-to-expire.png";
import windowPhase from "../../styles/images/window-phase-expire.png";
// import newUser from "../../styles/images/new-user-expire.png";
import newUser from "../../styles/images/new-user-expire2025.png";
//import renewUser from "../../styles/images/renew-expire2025.png";
import UserAgreementDialog from "./UserAgreementDialog";

const MemberInbox: React.FC = () => {
  const history = useHistory();
  const code = getQuery("code");
  const {
    memberLoading,
    memberInfo,
    fetchMemberInfo,
    changeMemberInfo,
    currentUser,
    isNewMember,
    windowExpire,
  } = useContext(authStore);
  const {
    wxLogin,
    fetchList,
    list,
    pagination,
    loading,
    activeTab,
    switchTab,
    inboxTabs,
    nextPage,
    setDialog,
    setDiscount,
    purchase,
  } = useContext(memberInboxStore);

  const [isShowModal, setIsShowModal] = useState(false);
  const [style, setStyle] = useState({});

  const onScrollEvent = () => {
    if (!memberInfo) {
      setStyle({
        overflowY: "hidden",
      });
      (document.querySelector(".member-inbox__list") as any).scrollTop = 0;
      wxLogin();
    } else if (!loading && pagination.hasNextPage) {
      const dom = document.getElementsByClassName("member-inbox__list")[0];
      if (
        dom.scrollTop + dom.clientHeight > dom.scrollHeight * 0.9 &&
        list.length < pagination.total
      ) {
        nextPage();
      }
    }
  };

  const codeFn = async (code: string) => {
    const endcode = window.sessionStorage.getItem("endCode") ? true : false;
    if (!endcode) {
      const result = await checkCode(code);
      if (result.status === "success" && result.message === "优惠码可使用") {
        // 有效
        window.sessionStorage.setItem("code", code);
        if (!window.currentUser) {
          // 未登录
          setDialog("guide-signin", true);
        } else if (window.currentUser && window.currentUser.mobile_verified) {
          //已登录&&实名认证
          const res = await specialCheckCode();
          if (res.status === "success" && res.message === "优惠码可使用") {
            // 调用兑换积分接口
            const exchangeRes = await exchangeCode(code);
            if (exchangeRes.status === "success") {
              message.success("试读积分领取成功，选一篇通讯立即解锁吧~", 5);
              window.sessionStorage.setItem("endCode", "end");
              fetchMemberInfo();
            } else {
              exchangeRes.memberMessage &&
              exchangeRes.memberMessage === "哎呀，手慢了~"
                ? setDialog("invalid-code", true)
                : message.error(
                    exchangeRes.memberMessage || exchangeRes.message
                  );
            }
          } else {
            // 当前帐号本月已兑换过积分
            res.message === "兑换失败，每个月只能兑换一次哦~"
              ? setDialog("exchange-error", true)
              : message.error(res.message);
          }
        }
      } else {
        // 邀请码无效(已使用)
        setDialog("invalid-code", true);
      }
    }
  };

  // 登录后的链接参数行为判定
  const loginAfterActionHandle = () => {
    const res1 = getQuery("member");
    const res2 = getQuery("year");
    const res3 = getQuery("cost");
    const res4 = getQuery("integral");
    // purchase: 标记是刚购买的新用户，不关闭页面不会再次提示，直到下次打开新页面
    if (res1 === "show" && !purchase) {
      // 新用户专享
      if (isNewMember) {
        setDialog("new-buy", true);
      } else {
        message.open({
          type: "error",
          duration: 3,
          className: "ant-toast",
          content: "仅限新用户享受福利哦~",
        });
      }
    } else if ((res2 === "show" || res3 === "show") && !purchase) {
      buyType(
        isNewMember,
        windowExpire,
        memberInfo.listVip,
        setDialog,
        setDiscount
      );
    } else if (res4 === "show" && !purchase) {
      // 积分空降
      if (memberInfo.listVip || (!memberInfo.listVip && windowExpire)) {
        setDialog("integral", true);
      } else {
        message.open({
          type: "error",
          duration: 3,
          className: "ant-toast",
          content: "仅限PRO会员用户购买~",
        });
      }
    } else {
      // 新人弹出
      isNewMember && setDialog("new-buy", true);
    }
  };

  useEffect(() => {
    !code && window.sessionStorage.removeItem("code");
    if (isWeiXin()) {
      // revert();
      //!todo: 因为后端接口并发会导致卡死,等后端修复后去掉定时器;
      setTimeout(() => {
        fetchMemberInfo();
      }, 500);
      code && codeFn(code); // 带有邀请码链接
      // 已登录未实名认证
      if (currentUser && !currentUser.mobile_verified) {
        history.replace("/auth");
      } else {
        // switchTab("comm");
        fetchList();
        window.sessionStorage.removeItem("id");
        window.sessionStorage.removeItem("login");
      }
    } else {
      if (isMobile()) {
        history.replace("/inbox-pc");
      } else {
        urlTranslate(history);
      }
    }
  }, []);

  useEffect(() => {
    if (!memberLoading) {
      if (memberInfo && isWxLogin()) {
        // 已登录未实名认证
        if (currentUser && !currentUser.mobile_verified) {
          history.replace("/auth");
        } else {
          const xy =
            memberInfo.proMemberLicensedRecordVersion ===
            memberInfo.systemProMemberLicenseVersion;
          // 会员需要判断是否同意最新协议
          if (memberInfo.listVip && !xy) {
            setDialog("user-agreement", true, {
              successCb: loginAfterActionHandle,
            });
          } else {
            loginAfterActionHandle();
          }
        }
      } else {
        // 1. 正常链接未登陆走的是btn click 触发登录和后续
        // 2. 特殊空降链接：1.新用户专享 2.购买积分 4.年度订阅全价弹窗 5.续费弹窗
        checkOrigin(
          () => setDialog("new-buy", true),
          () => setDialog("integral", true),
          () => {
            //年度订阅空降
            setDiscount(false); // 默认展示全价购买
            setDialog("cost", true);
          },
          () => {
            //会员续费空降
            setDiscount(true); // 默认展示折扣购买
            setDialog("cost", true);
          }
        );
      }
    }
  }, [memberLoading]);

  return (
    <div className="member-inbox-layout">
      <Helmet>
        <title>会员收件箱 ｜ 机器之心PRO</title>
      </Helmet>
      <CostDialog
        onSuccess={() => {
          window.localStorage.removeItem("login");
          fetchList();
        }}
      />
      <NewBuyDialog onSuccess={() => fetchList()} />
      <BuyIntegralDialog onSuccess={() => fetchList()} />
      <PayErrorDialog />
      <PaySuccessDialog />
      <InvalidCodeDialog />
      <GuideSigninDialog />
      <ExchangeErrorDialog />
      <EditInfoDialog
        name={memberInfo ? memberInfo.name || memberInfo.nickname : ""}
        company={memberInfo ? memberInfo.company : ""}
        position={memberInfo ? memberInfo.position : ""}
        onSubmit={(values: any) => changeMemberInfo(values)}
      />
      <UserAgreementDialog />
      <div className="member-inbox-content">
        <div className="member-inbox__header">
          <div className="member-inbox__header-title">
            <img className="title-img" src={titleImg} alt="title" />
            <div
              onClick={() => {
                if (!memberInfo) {
                  wxLogin();
                } else {
                  if (memberInfo.listVip) {
                    // 跳转搜索时，重置列表及相关参数
                    switchTab("comm", true);
                    history.push("/inbox-search");
                  } else {
                    message.open({
                      type: "error",
                      duration: 3,
                      className: "ant-toast",
                      content: "当前功能仅会员订阅用户可用，请先订阅会员！",
                    });
                    if (isNewMember) {
                      setDialog("new-buy", true);
                    } else {
                      //会员过期30天内折扣购买，会员过期超30天正价购买
                      setDiscount(windowExpire);
                      setDialog("cost", true);
                    }
                  }
                }
              }}
            >
              <Icon name="pro-search" size={20} color="#333" />
            </div>
          </div>
          <div className="member-inbox__header-bottom">
            {inboxTabs && (
              <div className="member-inbox__header-tabs">
                {inboxTabs.map((tab: any) => (
                  <div
                    key={tab.key}
                    className={`${activeTab.key === tab.key && "tab--active"}`}
                    onClick={() => switchTab(tab.key)}
                  >
                    <Icon
                      name={tab.icon}
                      size={20}
                      color={activeTab.key === tab.key ? "#fff" : "#333"}
                    />
                    {tab.tab}
                  </div>
                ))}
              </div>
            )}
            {!memberInfo ? (
              !loading && (
                <div
                  className="member-inbox__header-login"
                  onClick={() => wxLogin()}
                >
                  会员专属内容，请登录
                </div>
              )
            ) : (
              <div className="member-inbox__header-info">
                <div
                  className="member-inbox__header-user"
                  onClick={() => history.push("/inbox-center")}
                >
                  <div
                    className={`member-inbox__header-avatar ${memberInfo &&
                      memberInfo.listVip &&
                      "member-inbox__header-avatar--vip"}`}
                  >
                    <img
                      className="avatar-img"
                      src={memberInfo.avatarUrl}
                      alt="avatar"
                    />
                    {memberInfo && memberInfo.listVip && (
                      <img className="jewel-img" src={jewelImg} alt="jewel" />
                    )}
                  </div>
                  <div>
                    <p className="member-inbox__header-user-name">
                      {memberInfo.name || memberInfo.nickname}
                    </p>
                    {!!memberInfo.bio ? (
                      <p className="member-inbox__header-user-company">
                        {memberInfo.bio}
                      </p>
                    ) : (
                      <div
                        className="member-inbox__header-user-edit"
                        onClick={e => e.stopPropagation()}
                      >
                        暂无职务
                        <p onClick={() => setDialog("edit-info", true)}>
                          <Icon name="edit-icon" size={12} color="#939aaf" />
                          编辑
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {/* {memberInfo && (
                  <div className="member-inbox__header-member">
                    {memberInfo.listVip ? (
                      <>
                        <div
                          className="member-inbox__header-invoice"
                          onClick={() => setIsShowModal(true)}
                        >
                          <img src={invoiceImg} alt="invoice" />
                          开发票
                        </div>
                        <div className="member-inbox__header-vip-info">
                          <div
                            className="member-inbox__header-integral"
                            onClick={() =>
                              memberInfo.points <= 0 &&
                              setDialog("integral", true)
                            }
                          >
                            <img src={copperCash} alt="icon" />
                            {memberInfo.points}
                          </div>
                          {memberInfo.expiredAt0 && (
                            <p>{memberInfo.expiredAt0}到期</p>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="member-inbox__header-renew-wrapper">
                        <div
                          className="member-inbox__header-renew-points"
                          onClick={() => {
                            memberInfo.points <= 0 &&
                              windowExpire &&
                              setDialog("integral", true);
                          }}
                        >
                          <img src={copperCash} alt="icon" />
                          <p>
                            {memberInfo.points && memberInfo.points >= 0
                              ? memberInfo.points
                              : 0}
                          </p>
                        </div>
                        <div
                          className="member-inbox__header-renew-btn"
                          onClick={() => {
                            if (isNewMember) {
                              //新用户(从未购买过会员): 弹出首开弹窗
                              setDialog("new-buy", true);
                            } else {
                              //会员过期30天内(windowExpire)折扣购买，超30天正价购买
                              setDiscount(windowExpire); // 设置是否折扣购买
                              setDialog("cost", true);
                            }
                          }}
                        >
                          会员订阅
                        </div>
                      </div>
                    )}
                  </div>
                )} */}
              </div>
            )}
          </div>
        </div>
        {memberInfo && (
          <div
            className="member-inbox__expire"
            onClick={() => {
              if (isNewMember) {
                //新用户:从未购买过会员
                setDialog("new-buy", true);
              } else if (
                (memberInfo.listVip &&
                  memberInfo.dueExpiredDay > 0 &&
                  memberInfo.dueExpiredDay <= 30) ||
                (!memberInfo.listVip && windowExpire)
              ) {
                //即将到期、会员过期30天内windowExpire
                setDiscount(true); // 设置是否折扣购买
                setDialog("cost", true);
              }
            }}
          >
            {/* 新用户 */}
            {isNewMember && <img src={newUser} alt="expire" />}
            {/* 即将到期 + 窗口期 */}
            {/* {((memberInfo.listVip &&
              memberInfo.dueExpiredDay > 0 &&
              memberInfo.dueExpiredDay <= 30) ||
              (!memberInfo.listVip && windowExpire)) && (
              <img src={renewUser} alt="expire" />
            )} */}
            {/* 即将到期 */}
            {memberInfo.listVip &&
              memberInfo.dueExpiredDay > 0 &&
              memberInfo.dueExpiredDay <= 30 && (
                <>
                  <img src={aboutTo} alt="expire" />
                  <p>
                    您的会员剩余 <span>{memberInfo.dueExpiredDay}</span> 天到期
                  </p>
                </>
              )}
            {/* 窗口期：会员过期30天内windowExpire */}
            {!memberInfo.listVip && windowExpire && (
              <>
                <img src={windowPhase} alt="expire" />
                <div>
                  <span>{memberInfo.baseExpiredAt}</span>
                  前续费立省￥210！单期低至￥6.4！
                </div>
              </>
            )}
          </div>
        )}
        <div
          className="member-inbox__list"
          style={style}
          onScrollCapture={onScrollEvent}
        >
          {activeTab.key === "refer" && (
            <div className="member-inbox__total">
              <p>
                共 <span>{pagination.total}</span> 篇参考内容
              </p>
            </div>
          )}
          {list &&
            list.length > 0 &&
            list.map((item: any, index: number) => (
              <MemberInboxCard key={index} model={item} />
            ))}
          {loading && <Loading />}
        </div>
      </div>
      {isShowModal && (
        <AssistantCodeModal
          visible={isShowModal}
          desc="添加小助手后，回复「开发票」"
          onCancel={() => {
            setIsShowModal(false);
          }}
        />
      )}
    </div>
  );
};

export default observer(MemberInbox);
