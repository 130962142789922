import React, { useEffect, useContext, useState, useRef } from "react";
import { observer } from "mobx-react";

import "./reference-report-info.scss";
import AuthStore from "../../stores/authStore";
import CorrespondenceStore from "../../stores/correspondenceStore";
// 后期再看需要挪过来吗?
import buyType from "../correspondence/buyType";
import { Loading, Icon } from "../shared";
import lockIcon from "../../styles/images/inbox__lock-icon.png";

const ReferenceReport: React.FC = () => {
  const thumbnailsRef = useRef(null);
  const { memberInfo, isNewMember, windowExpire } = useContext(AuthStore);
  const {
    detail,
    detailLoading,
    setDialog,
    updateActiveThumbnail,
    setDiscount,
  } = useContext(CorrespondenceStore);

  const [arrowLeftColor, setArrowLeftColor] = useState("#C6CBD4");
  const [arrowRightColor, setArrowRightColor] = useState("#C6CBD4");
  const [buy, setBuy] = useState(false);
  const [activeTab, setActiveTab] = useState("intro");

  useEffect(() => {
    if (memberInfo && detail) {
      if (memberInfo.points >= detail.unlockSpend) {
        setBuy(true);
      }
    }
  }, [memberInfo, detail]);

  const changeTab = (type: string) => {
    setActiveTab(type);
    //滚动到报告简介位置
    const activeEl = document.querySelector(`.content-${type}`) as any;
    if (activeEl) {
      activeEl.scrollIntoView({ behavior: "smooth" }); // 平滑滚动至目标位置
    }
  };

  const handleLeftClick = () => {
    if (!(detail.reportCatalog.activeIndex === 0)) {
      updateActiveThumbnail(detail.reportCatalog.activeIndex - 1);
      if (
        detail.reportCatalog.activeIndex <
        detail.reportCatalog.images.length - 2
      ) {
        thumbnailsRef.current.scrollTo({ right: 330 });
      }
    }
  };

  const handleRightClick = () => {
    if (
      !(
        detail.reportCatalog.activeIndex ===
        detail.reportCatalog.images.length - 1
      )
    ) {
      updateActiveThumbnail(detail.reportCatalog.activeIndex + 1);
      if (detail.reportCatalog.activeIndex > 2) {
        thumbnailsRef.current.scrollTo({ left: 330 });
      }
    }
  };

  return detailLoading ? (
    <Loading />
  ) : (
    <div
      className="reference-report__info"
      style={
        (memberInfo && memberInfo.listVip) || detail.unlocked
          ? {
              paddingBottom: "76px",
            }
          : {
              paddingBottom: "30px",
            }
      }
    >
      <div className="reference-report__header">
        {detail.fullCoverImageUrl && (
          <div className="content__cover">
            <img src={detail.fullCoverImageUrl} alt="front-cover" />
            {/* 灰条1 */}
            <div className="content__line1" />
            {/* 灰条2 */}
            <div className="content__line2" />
          </div>
        )}
        <div className="content__info">
          {detail.title && <p className="content__title">{detail.title}</p>}
          {(detail.author || detail.date) && (
            <div className="content-info">
              {detail.author && <span>{detail.author}</span>}
              {detail.author && detail.date && <span>|</span>}
              {detail.date && <span>{detail.date}</span>}
            </div>
          )}
          <div className="content-group">
            {detail.hotTags && detail.hotTags.length > 0 && (
              <div className="content-tags">
                标签：
                <p>{detail.hotTags.join("、")}</p>
              </div>
            )}
            {detail.pageSize && (
              <div className="content-pagesize">
                报告页数：
                <p>{detail.pageSize}</p>
              </div>
            )}
          </div>

          {((memberInfo && memberInfo.listVip) || detail.unlocked) && (
            <div
              className="content-btn-read"
              onClick={() => {
                window.open(detail.fullFileUrl, "_target");
              }}
            >
              查看报告
            </div>
          )}
        </div>
      </div>
      <div className="content-tabs">
        <div
          className={`content-tab ${activeTab === "intro" &&
            "content-tab--active"}`}
          onClick={() => changeTab("intro")}
        >
          报告简介
        </div>
        <div
          className={`content-tab ${activeTab === "catalog" &&
            "content-tab--active"}`}
          onClick={() => changeTab("catalog")}
        >
          报告目录
        </div>
      </div>
      {detail.summary && (
        <>
          <div className="content__tab-item content-intro">
            <Icon name="pro-institution-intro" />
            <span className="content__tab-item__title">报告简介</span>
          </div>
          <div
            className="content-block"
            dangerouslySetInnerHTML={{
              __html: detail.summary,
            }}
          />
        </>
      )}
      {detail.reportCatalog && detail.reportCatalog.images.length > 0 && (
        <>
          <div className="content__tab-item content-catalog">
            <Icon name="pro-catalog" />
            <span className="content__tab-item__title">报告目录</span>
          </div>
          <div className="report-catalog content-block">
            <img
              className="report-catalog__main-image"
              src={
                detail.reportCatalog.images[detail.reportCatalog.activeIndex]
                  .preview
              }
              alt="报告目录"
            />
            <div className="report-catalog__thumbnails">
              <div
                onClick={handleLeftClick}
                onMouseEnter={() => {
                  setArrowLeftColor("#0062ff");
                }}
                onMouseLeave={() => {
                  setArrowLeftColor("#C6CBD4");
                }}
              >
                <Icon
                  name="arrow-left-with-circle"
                  size={20}
                  color={arrowLeftColor}
                />
              </div>
              <div
                className="report-catalog__thumbnails-images"
                ref={thumbnailsRef}
              >
                {detail.reportCatalog.images.map((item, i) => {
                  const activeClass =
                    detail.reportCatalog.activeIndex === i
                      ? "thumbnail--active"
                      : "";

                  return (
                    <img
                      className={activeClass}
                      tabIndex={0}
                      key={i}
                      src={item.thumbnail}
                      alt="报告目录缩略图"
                      onClick={() => updateActiveThumbnail(i)}
                    />
                  );
                })}
              </div>
              <div
                onClick={handleRightClick}
                onMouseEnter={() => {
                  setArrowRightColor("#0062ff");
                }}
                onMouseLeave={() => {
                  setArrowRightColor("#C6CBD4");
                }}
              >
                <Icon
                  name="arrow-right-with-circle"
                  size={20}
                  color={arrowRightColor}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="content-btn-group">
        {(memberInfo && memberInfo.listVip) || detail.unlocked ? (
          <></>
        ) : (
          <div className="report-private-area">
            <div className="report-private-area__locked">
              <img
                className="report-private-area__icon"
                src={lockIcon}
                alt="lock"
              />
              <p>尚未解锁</p>
            </div>
            {buy ? (
              <p className="report-private-area__bottom">
                当前文章需要 <span>{detail.unlockSpend}</span> 积分解锁，剩余{" "}
                <span className={!buy || !memberInfo.points ? "un-buy" : ""}>
                  {memberInfo ? memberInfo.points : 0}
                </span>{" "}
                积分
              </p>
            ) : (
              <div
                className="report-private-area__btn report-private-area__btn-extra"
                onClick={() => {
                  buyType(
                    isNewMember,
                    windowExpire,
                    memberInfo.listVip,
                    setDialog,
                    setDiscount
                  );
                }}
              >
                订阅会员，解锁全文
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ReferenceReport);
